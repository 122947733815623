/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-global-network-select-wrapper {
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 1001;
}
.ant-global-network-select-wrapper::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.ant-global-network-select-wrapper-open::before {
  pointer-events: auto;
  opacity: 0.75;
}
.ant-global-network-select-wrapper-open .ant-select-open + div {
  top: 6px !important;
}
.ant-global-network-select-wrapper .ant-select-arrow {
  right: 24px;
}
.ant-global-network-select-wrapper .ant-select-arrow .anticon {
  color: var(--menu-item-active-color);
}
.ant-global-network-select-dropdown {
  background-color: var(--menu-bg) !important;
  border-radius: 0;
  padding: 0;
}
.ant-global-network-select-dropdown::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ant-global-network-select-dropdown .ant-select-item {
  padding: 12px;
}
.ant-global-network-select-dropdown .ant-select-item-option {
  background-color: transparent;
}
.ant-global-network-select-dropdown .ant-select-item-option-active {
  background-color: transparent;
}
.ant-global-network-select-dropdown .ant-select-item-option:hover:not(.ant-select-item-option-disabled) {
  background-color: rgba(var(--menu-item-active-bg-rgb), 0.1) !important;
}
.ant-global-network-select-dropdown .ant-select-item-option-selected {
  background-color: rgba(var(--menu-item-active-bg-rgb), 0.1) !important;
}
.ant-global-network-select-dropdown .ant-select-item-option-selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: var(--menu-item-active-bg);
}
.ant-global-network-select-selector {
  height: 100%;
  width: 100%;
}
.ant-global-network-select-selector .ant-select-selector {
  height: 100% !important;
  width: 100%;
  border-radius: 0 !important;
  background-color: var(--menu-item-active-bg) !important;
  border: none !important;
}
.ant-global-network-select-selector .ant-select-selector .ant-global-network-select-item-title,
.ant-global-network-select-selector .ant-select-selector .ant-global-network-select-item-subtitle {
  line-height: 1;
  color: var(--menu-item-active-color);
}
.ant-global-network-select-selector .ant-select-selection-search {
  display: none;
}
.ant-global-network-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 32px !important;
}
.ant-global-network-select-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 0 12px;
}
.ant-global-network-select-item-title,
.ant-global-network-select-item-subtitle {
  line-height: 1;
  color: var(--menu-item-color);
}
.ant-global-network-select-item-active .ant-global-network-select-item-title,
.ant-global-network-select-item-active .ant-global-network-select-item-subtitle {
  color: var(--menu-item-active-color);
}
.ant-global-network-select-item .ant-image-img {
  max-width: 36px;
}
.ant-global-network-select-wrapper-collapsed .ant-select-selection-item {
  padding-right: 0 !important;
}
.ant-global-network-select-wrapper-collapsed .ant-global-network-select-item {
  display: block;
  text-align: center;
}
.ant-global-network-select-wrapper-collapsed .ant-global-network-select-item-text {
  display: none !important;
}
.ant-global-network-select-wrapper-collapsed .ant-global-network-select-item-title {
  display: none !important;
}
.ant-global-network-select-wrapper-collapsed .ant-global-network-select-item-subtitle {
  display: none !important;
}
