/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge-status-dot::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-primary {
  background-color: var(--primary-color);
}
.ant-badge-status-primary::after {
  border: 1px solid var(--primary-color);
}
.ant-badge-status-processing {
  background-color: var(--info-color);
}
.ant-badge-status-processing::after {
  border: 1px solid var(--info-color);
}
.ant-badge-status-warning {
  background-color: var(--warning-color);
}
.ant-badge-status-warning::after {
  border: 1px solid var(--warning-color);
}
.ant-badge-status-danger {
  background-color: var(--danger-color);
}
.ant-badge-status-danger::after {
  border: 1px solid var(--danger-color);
}
.ant-badge-status-success {
  background-color: var(--success-color);
}
.ant-badge-status-success::after {
  border: 1px solid var(--success-color);
}
.ant-badge-counter .ant-badge-count {
  color: var(--badge-count-color);
  background-color: var(--badge-count-bg);
}
