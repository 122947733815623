/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-payment-card {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;
  padding: 24px;
  font-family: var(--font-family-primary);
  letter-spacing: 0.8px;
  background: #f8f8f8;
  background: linear-gradient(0deg, #f8f8f8 0%, #f8f8f8 100%);
  border-radius: var(--border-radius-lg);
  opacity: 1;
  overflow: hidden;
  max-width: 350px;
}
@media (max-width: 575px) {
}
.ant-payment-card::before {
  content: '';
  position: absolute;
  width: 250%;
  height: 200%;
  left: -88%;
  top: 27%;
  background-color: #787878;
  border-radius: 50%;
  transform: rotate(350deg);
}
.ant-payment-card .card-number {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 14px;
  letter-spacing: 2px;
}
.ant-payment-card .ant-payment-brand {
  display: flex;
}
.ant-payment-card .ant-typography {
  text-transform: uppercase;
}
.ant-payment-card-title.ant-typography {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
}
.ant-payment-card h2,
.ant-payment-card h3,
.ant-payment-card span {
  font-weight: bold;
}
.ant-payment-card h2 {
  font-size: 14px;
  line-height: 20px;
}
.ant-payment-card h3 {
  font-size: 10px;
  line-height: 14px;
}
.ant-payment-card span {
  font-size: 12px;
  line-height: 17px;
}
.ant-payment-card-visa::before {
  background: #00279c;
  background: linear-gradient(0deg, #00279c 0%, #181b60 100%);
}
.ant-payment-card-visa h2.ant-typography,
.ant-payment-card-visa h3.ant-typography,
.ant-payment-card-visa h4.ant-typography,
.ant-payment-card-visa h5.ant-typography,
.ant-payment-card-visa span {
  color: white;
}
.ant-payment-card-amex::before {
  background: #2e77bb;
  background: linear-gradient(90deg, #2e77bb 0%, #1d8ece 100%);
}
.ant-payment-card-amex h2.ant-typography,
.ant-payment-card-amex h3.ant-typography,
.ant-payment-card-amex h4.ant-typography,
.ant-payment-card-amex h5.ant-typography,
.ant-payment-card-amex span {
  color: white;
}
.ant-payment-card-discover::before {
  background: #e66a1c;
  background: linear-gradient(90deg, #e66a1c 0%, #f89e20 100%);
}
.ant-payment-card-discover h2.ant-typography,
.ant-payment-card-discover h3.ant-typography,
.ant-payment-card-discover h4.ant-typography,
.ant-payment-card-discover h5.ant-typography,
.ant-payment-card-discover span {
  color: white;
}
.ant-payment-card-mastercard::before {
  background: #f79e1b;
  background: linear-gradient(90deg, #f79e1b 0%, #eb001b 100%);
}
.ant-payment-card-mastercard h2.ant-typography,
.ant-payment-card-mastercard h3.ant-typography,
.ant-payment-card-mastercard h4.ant-typography,
.ant-payment-card-mastercard h5.ant-typography,
.ant-payment-card-mastercard span {
  color: white;
}
