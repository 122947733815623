/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer-detail-image {
  height: 215px;
  overflow: hidden;
}
.ant-drawer-detail-image .ant-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.ant-drawer-detail-icon-buttons .ant-btn {
  padding: 6px;
}
.ant-drawer-detail-card .ant-card-meta-title {
  margin-bottom: 24px;
}
.ant-drawer-detail-card .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-drawer-detail-card .ant-btn .anticon + span {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 8px);
}
