/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
a,
a.ant-typography {
  color: var(--link-color);
}
a:hover,
a.ant-typography:hover {
  color: var(--link-hover-color);
}
a:active,
a.ant-typography:active {
  color: var(--link-active-color);
}
.ant-tooltip-arrow-content {
  background-color: var(--tooltip-bg);
}
.ant-tooltip-inner {
  background-color: var(--tooltip-bg);
}
.ant-tooltip a {
  color: var(--tooltip-color);
}
.ant-layout-sider .ant-layout-header,
.ant-layout-sider .ant-layout-footer {
  background-color: inherit;
  color: var(--menu-item-color);
}
.ant-layout-sider.portal-navigation {
  z-index: 3;
}
.ant-layout-sider.portal-navigation.fixed-left {
  min-height: 100vh;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation {
  height: 100%;
  max-height: calc(100vh - (112px * 2));
  overflow-y: scroll;
  padding: 0 0 20px !important;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation::-webkit-scrollbar {
  display: none;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation .ant-menu.ant-main-menu {
  position: relative;
  padding: 0;
  overflow-y: unset;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation .ant-menu.ant-main-menu::-webkit-scrollbar {
  display: none;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation .ant-menu.ant-main-menu .ant-menu-item {
  text-align: left;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation .ant-menu.ant-main-menu .ant-menu-item-text {
  margin-left: 0;
  transition: none;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-sider-navigation .ant-menu.ant-main-menu.ant-menu-inline-collapsed {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-footer,
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header {
  background-color: var(--menu-bg);
  height: 112px;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-footer::after {
  bottom: 112px;
  background: linear-gradient(180deg, rgba(var(--menu-bg-rgb), 0) 0%, rgba(var(--menu-bg-rgb), 0.8) 100%);
  background-color: var(--menu-bg);
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header::after {
  top: var(layout-header-height);
  background: linear-gradient(180deg, white 0%, rgba(var(--menu-bg-rgb), 0.8) 100%);
  background-color: var(--menu-bg);
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header {
  padding: 40px;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header .ant-portal-navigation-header-link {
  display: flex;
  width: 100%;
}
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header .ant-portal-navigation-header-link .ant-logo-icon,
.ant-layout-sider.portal-navigation.fixed-left .ant-layout-header .ant-portal-navigation-header-link .ant-logo-text {
  object-fit: contain;
}
.ant-layout-sider.portal-navigation.fixed-left.ant-layout-sider-collapsed .ant-layout-header {
  padding: 40px 24px;
}
.ant-layout-sider.portal-navigation .ant-menu-item {
  padding-left: 0;
  color: var(--menu-item-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item a {
  color: var(--menu-item-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item-active {
  background-color: var(--menu-item-hover-bg) !important;
  color: var(--menu-item-hover-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item-active .anticon,
.ant-layout-sider.portal-navigation .ant-menu-item-active .ant-menu-item-text a {
  color: var(--menu-item-hover-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item-selected,
.ant-layout-sider.portal-navigation .ant-menu-item-selected:hover {
  background-color: var(--menu-item-active-bg) !important;
  color: var(--menu-item-active-color) !important;
}
.ant-layout-sider.portal-navigation .ant-menu-item-selected .ant-menu-item-text,
.ant-layout-sider.portal-navigation .ant-menu-item-selected:hover .ant-menu-item-text,
.ant-layout-sider.portal-navigation .ant-menu-item-selected .anticon,
.ant-layout-sider.portal-navigation .ant-menu-item-selected:hover .anticon,
.ant-layout-sider.portal-navigation .ant-menu-item-selected a,
.ant-layout-sider.portal-navigation .ant-menu-item-selected:hover a {
  color: var(--menu-item-active-color) !important;
}
.ant-layout-sider.portal-navigation .ant-menu-item-active,
.ant-layout-sider.portal-navigation .ant-menu-item:hover:not(.ant-layout-sider.portal-navigation .ant-menu-item-selected),
.ant-layout-sider.portal-navigation .ant-menu-item:focus {
  background-color: var(--menu-item-hover-bg);
  color: var(--menu-item-hover-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item-active a,
.ant-layout-sider.portal-navigation .ant-menu-item:hover:not(.ant-layout-sider.portal-navigation .ant-menu-item-selected) a,
.ant-layout-sider.portal-navigation .ant-menu-item:focus a,
.ant-layout-sider.portal-navigation .ant-menu-item-active .anticon,
.ant-layout-sider.portal-navigation .ant-menu-item:hover:not(.ant-layout-sider.portal-navigation .ant-menu-item-selected) .anticon,
.ant-layout-sider.portal-navigation .ant-menu-item:focus .anticon {
  color: var(--menu-item-hover-color);
}
.ant-layout-sider.portal-navigation .ant-menu-item .anticon {
  line-height: 1;
}
.ant-layout-sider .ant-menu {
  width: 100%;
  padding-top: var(--layout-section-padding-vertical);
  padding-bottom: var(--layout-section-padding-vertical);
  transition: background-color 0.2s;
  background: transparent;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-right,
.ant-layout-sider .ant-menu-vertical-left {
  border-right: none;
  border-left: none;
}
.ant-layout-sider .ant-menu-horizontal .ant-menu-item {
  padding: 8px 20px !important;
}
.ant-layout-sider .ant-menu-inline-collapsed .ant-menu-item .anticon {
  line-height: 1;
}
.ant-layout-sider .ant-menu-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 40px;
  font-weight: var(--menu-item-font-weight);
  font-family: var(--menu-item-font-family);
  font-size: var(--menu-item-font-size);
  line-height: var(--menu-item-height);
  letter-spacing: 0.5px;
  text-transform: var(--menu-item-text-transform);
  border-left: 2px solid transparent;
}
.ant-layout-sider .ant-menu-vertical .ant-menu-item {
  padding: 0 40px;
}
.ant-drawer-mobile-sider .ant-drawer-content-wrapper {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-mobile-sider .ant-drawer-header-close-only {
  position: absolute;
}
.ant-drawer-mobile-sider .ant-drawer-header-close-only .ant-drawer-close {
  opacity: 0;
}
.ant-drawer-mobile-sider.ant-drawer-open .ant-drawer-header-close-only {
  position: relative !important;
}
.ant-drawer-mobile-sider.ant-drawer-open .ant-drawer-header-close-only .ant-drawer-close {
  opacity: 1;
  top: 40px;
  right: -24px;
  padding: 0;
}
