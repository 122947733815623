/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--primary-color);
}
.ant-radio-inner {
  border-radius: 50%;
}
.ant-radio-inner::after {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;
}
