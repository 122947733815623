/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
  background-color: var(--avatar-bg);
  color: var(--avatar-color);
  font-size: 18px;
}
.ant-avatar-circle {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.ant-avatar-circle .anticon {
  vertical-align: 0;
}
.ant-avatar.ant-avatar-background-none {
  background-color: transparent;
}
.ant-avatar.ant-avatar-background-primary {
  background-color: var(--primary-color);
}
.ant-avatar.ant-avatar-background-warning {
  background-color: var(--warning-color);
}
.ant-avatar.ant-avatar-background-danger {
  background-color: var(--danger-color);
}
.ant-avatar.ant-avatar-background-info {
  background-color: var(--info-color);
}
.ant-avatar.ant-avatar-background-light {
  background-color: var(--avatar-bg);
}
.ant-avatar.ant-avatar-background-dark {
  background-color: var(--text-color-secondary);
}
