/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#root .ant-layout-header-wrapper {
  top: 0;
  position: fixed;
  width: 100%;
  background: var(--body-background);
  z-index: 2;
  transition: all 0.3s ease;
  padding: 0 24px;
  height: var(--layout-header-mobile-height);
}
#root .ant-layout-header-wrapper + .ant-layout {
  padding-top: calc(var(--layout-header-mobile-height) + 12px);
}
@media (min-width: 768px) {
  #root .ant-layout-header-wrapper {
    padding: 0 40px;
  }
}
#root .ant-layout-header-wrapper-scrolled {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.ant-layout-header {
  display: flex;
  align-items: center;
  line-height: 1;
}
.ant-header-schedule .injected-svg {
  width: 28px !important;
}
.ant-layout-header-nav {
  width: 100%;
  height: 100%;
}
.ant-layout-header-nav-item {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 16px 0;
}
.ant-layout-header-nav-item:nth-child(1) {
  justify-content: flex-start;
  flex: 1;
}
.ant-layout-header-nav-item:nth-child(2) {
  justify-content: center;
  max-width: 200px;
}
.ant-layout-header-nav-item:nth-child(3) {
  justify-content: flex-end;
  flex: 1;
}
.ant-layout-header-nav-item-logo {
  padding: 16px;
}
.ant-layout-header-nav-item .ant-logo {
  height: 100%;
  max-width: unset;
}
.ant-drawer-left .ant-drawer-close {
  top: 40px;
  right: -32px;
}
