/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  line-height: 1;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer;
  font-family: var(--tag-font-family);
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: var(--text-color);
  border-top-right-radius: 0;
}
.ant-tag .ant-space-item * {
  margin-bottom: 0;
}
.ant-tag.ant-tag-disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.ant-tag-not-checkable {
  cursor: default;
  pointer-events: none;
}
.ant-tag.ant-tag-asymmetric {
  border-radius: 8px 2px 8px 8px;
}
.ant-tag.ant-tag-asymmetric::before {
  border-radius: 8px 2px 8px 8px;
}
.ant-tag.ant-tag-default {
  color: var(--tag-default-color);
  background-color: var(--tag-default-bg);
}
.ant-tag.ant-tag-info {
  position: relative;
  color: var(--info-color) !important;
  background: none !important;
}
.ant-tag.ant-tag-info::before {
  content: '';
  background-color: var(--info-color);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-tag.ant-tag-error {
  position: relative;
  color: var(--danger-color) !important;
  border-color: rgba(var(--danger-color-rgb), 0.2);
  background-color: rgba(var(--danger-color-rgb), 0.2);
}
.ant-tag.ant-tag-processing {
  position: relative;
  color: var(--info-color) !important;
  background: none !important;
}
.ant-tag.ant-tag-processing::before {
  border-top-right-radius: 0;
  content: '';
  background-color: var(--info-color);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-tag.ant-tag-primary {
  color: #57806e !important;
  background-color: var(--alert-success-bg-color) !important;
}
.ant-tag.ant-tag-success {
  color: var(--text-color-inverse);
  background-color: var(--success-color);
}
.ant-tag.ant-tag-warning {
  color: var(--warning-color) !important;
  background-color: rgba(var(--warning-color-rgb), 0.2) !important;
  border: none;
}
.ant-tag.ant-tag-ghost {
  background-color: transparent;
  border: 1px solid var(--border-color-base);
}
.ant-tag.ant-tag-middle {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.ant-tag.ant-tag-large {
  height: 35px;
  line-height: 35px;
  font-weight: 700;
  font-size: 14px;
  font-family: var(--font-family-primary);
  border: 1px solid var(--border-color-base);
  padding: 0 8px;
}
.ant-tag.ant-tag-small {
  font-size: 10px;
}
.ant-tag.ant-tag-checked {
  color: var(--text-color-inverse);
  background-color: var(--primary-color);
}
.ant-tag.ant-tag-checked * {
  color: var(--text-color-inverse);
}
.ant-tag-ghost.ant-tag-checked {
  color: var(--text-color-inverse);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.ant-tag-ghost.ant-tag-checked * {
  color: var(--text-color-inverse);
}
.ant-tag-close-icon svg {
  fill: var(--text-color-inverse);
  margin-left: 5px;
  height: 12px !important;
  width: 12px !important;
}
