/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card-meta-with-tag .ant-card-meta-detail {
  width: 100%;
}
.ant-card-meta-with-tag .ant-card-meta-description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
