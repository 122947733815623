/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-descriptions-row .ant-descriptions-item-label {
  color: var(--table-header-color);
}
.ant-descriptions-row .ant-descriptions-item-content {
  background-color: var(--table-header-bg);
  color: var(--table-header-color);
}
.ant-descriptions-row .ant-descriptions-item-content * {
  color: var(--table-header-color);
}
