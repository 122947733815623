/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card {
  background-color: var(--card-bg);
  border-color: var(--border-color-base);
  border-radius: var(--border-radius-base);
}
.ant-card:not(.ant-card-bordered) .ant-card-body {
  padding: 0;
}
.ant-card .ant-typography-ellipsis-multiple-line {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-pack: end;
  -webkit-box-orient: vertical;
  max-height: 64px;
  overflow: hidden;
}
.ant-card .anticon {
  color: var(--icon-color);
}
.ant-card.ant-card-with-badge .ant-card-cover {
  height: 180px;
  flex: 0 0 40%;
  margin-bottom: 0;
}
.ant-card.ant-card-with-badge .ant-card-body {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  flex: 0 0 60%;
}
.ant-card.ant-card-with-badge .ant-card-badge {
  top: 140px;
}
.ant-card.ant-card-with-badge .ant-image {
  position: relative;
  height: 100%;
}
.ant-card.ant-card-with-badge .ant-image-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-card.ant-card-with-gallery .ant-card-cover {
  height: 285px;
  max-width: 100%;
  overflow: hidden;
}
.ant-card-primary .ant-card-cover {
  margin-bottom: 16px;
  background-color: #f8f8f8;
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
}
.ant-card-primary .ant-card-cover .ant-image-img {
  object-fit: cover;
  object-position: center center;
  height: 100%;
}
.ant-card-primary .ant-card-cover .ant-image-error {
  display: flex;
  justify-content: center;
  width: unset;
}
.ant-card-primary .ant-card-cover .ant-image-error .ant-image-img {
  object-fit: contain;
}
.ant-card .ant-card-body-wrapper.ant-row > .ant-col {
  min-height: 1.5715em;
}
.ant-card .ant-card-body-wrapper.ant-space-vertical > .ant-space-item {
  min-height: 1.5715em;
}
.ant-card-secondary {
  text-align: center;
}
.ant-card-secondary .ant-card-cover {
  margin: 24px;
}
.ant-card .ant-avatar-circle {
  margin-top: 8px;
  border: 8px solid var(--border-color-base);
  border-radius: 50%;
}
.ant-card-hoverable {
  box-shadow: var(--card-shadow);
}
.ant-card-hoverable:hover {
  border-color: var(--card-hoverable-hover-border);
}
.ant-card-hoverable:hover {
  border-color: var(--colors-gray-3);
  box-shadow: var(--card-hoverable-hover-shadow);
}
.ant-card-subtitle {
  font-family: var(--card-subtitle-font-family);
}
.ant-card-meta-details {
  margin-top: auto;
  font-family: var(--card-meta-font-family);
}
.ant-card-meta-title {
  margin-bottom: 0;
}
.ant-card-meta-title h2,
.ant-card-meta-title h3,
.ant-card-meta-title h4,
.ant-card-meta-title h5 {
  margin-bottom: 0;
  white-space: normal;
}
.ant-card-meta-title-wrapper {
  font-size: unset;
}
.ant-card-meta-title-wrapper h2,
.ant-card-meta-title-wrapper h3,
.ant-card-meta-title-wrapper h4,
.ant-card-meta-title-wrapper h5 {
  font-weight: 900;
  font-size: 16px;
  white-space: pre-wrap;
}
.ant-card-meta-title + .ant-card-meta-description {
  margin-top: 8px;
}
.ant-card.ant-card-cover-bordered .ant-card-cover {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.ant-card.ant-card-equal-height {
  height: 100%;
}
.ant-card.ant-card-equal-height .ant-card-cover {
  border: 1px solid transparent;
  background: transparent;
  flex: 1 1 180px;
  height: 180px;
  margin-bottom: 0;
}
.ant-card.ant-card-equal-height .ant-card-cover .ant-image {
  height: 180px;
}
.ant-card.ant-card-equal-height .ant-card-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(100% - 180px);
  height: calc(100% - 180px);
}
.ant-card .ant-card-badge {
  position: absolute;
  top: 128px;
  left: calc(40px - 8px);
}
.ant-card.ant-card-with-footer .ant-card-body {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 24px;
  height: calc(100% - 180px);
}
.ant-card.ant-card-with-footer .ant-card-body .ant-card-meta {
  margin-bottom: 15px;
}
.ant-card.ant-card-with-footer-flush .ant-card-body {
  padding-bottom: 0;
}
.ant-card.ant-card-with-footer .ant-card-footer {
  font-family: var(--card-footer-font-family);
  margin-bottom: 0;
  margin-top: auto;
}
.ant-card.ant-card-with-footer .ant-card-footer:not(.ant-card.ant-card-with-footer .ant-card-footer-flush) {
  padding-top: 24px;
}
.ant-card.ant-card-with-footer .ant-card-footer-flush {
  margin-left: -24px;
  margin-right: -24px;
}
.ant-card.ant-card-with-footer .ant-card-footer-bordered {
  border-top: 1px solid var(--border-color-base);
}
@media (max-width: 991px) {
  .ant-card.ant-card-flush {
    margin: 0 -24px;
    padding: 24px;
  }
}
