/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  z-index: 1001;
}
.ant-modal-content {
  background-color: var(--modal-bg);
  border-radius: var(--border-radius-base);
}
.ant-modal-footer {
  background-color: var(--modal-footer-bg);
}
.ant-modal-close {
  color: var(--modal-close-color);
}
