/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-phone-form-container {
  display: flex;
  flex: 1 1;
}
.ant-phone-form-container .ant-input-number {
  width: 100%;
  color: var(--input-color);
}
.ant-phone-form-container .ant-input-number-input {
  padding: 24px 12px 12px !important;
  height: unset !important;
  width: 100%;
}
.ant-phone-form-container .ant-input-number-handler-wrap {
  display: none;
}
.ant-phone-form-container .ant-phone-form-wrapper {
  position: relative;
  display: inline-flex;
  height: 100%;
  flex: 1 1;
  border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
  border-radius: var(--input-border-radius);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  transition: all 0.3s;
}
.ant-phone-form-container .ant-phone-form-wrapper:hover,
.ant-phone-form-container .ant-phone-form-wrapper-focused {
  border-color: var(--input-hover-border-color);
}
.ant-phone-form-container .ant-phone-form-wrapper .ant-phone-form-input {
  border: none;
}
.ant-phone-form-container .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.ant-phone-form-container .ant-country-code-value {
  display: flex;
  flex: none;
  padding: 24px 0 12px 12px;
  pointer-events: none;
  background-color: var(--input-bg);
  color: var(--input-color);
}
.ant-phone-form-container .ant-country-code-value + .ant-phone-form-input-wrapper {
  position: static;
}
.ant-phone-form-container .ant-country-code-value + .ant-phone-form-input-wrapper .ant-input-number-input {
  color: var(--input-color);
  padding-left: 6px;
}
.ant-phone-form-container .ant-phone-form-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-phone-form-container .ant-phone-form-input-wrapper {
  flex: 1;
  margin-bottom: 0;
}
.ant-phone-form-container .ant-phone-form-input-content {
  flex: 1;
  color: var(--input-color);
  background-color: var(--input-bg);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--input-border-radius);
  border-top-right-radius: var(--input-border-radius);
  border-color: var(--input-border-color);
  border-width: var(--border-width-base);
  border-style: var(--border-style-base);
}
.ant-phone-form-container .ant-form-item-label + .ant-form-item-control .ant-input-affix-wrapper {
  padding-left: 32px;
}
.ant-phone-form-container .ant-phone-code-form-container .ant-phone-form-select.ant-select .ant-select-selector {
  height: calc(var(--input-height-lg) + 12px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: var(--input-border-color);
  border-right-color: transparent !important;
}
.ant-phone-form-container .ant-phone-code-form-container:hover .ant-phone-form-select.ant-select .ant-select-selector,
.ant-phone-form-container .ant-phone-code-form-container:hover .ant-phone-form-select.ant-select .ant-select-single-selector {
  border-color: var(--input-hover-border-color) !important;
  border-right-color: transparent !important;
}
.ant-phone-form-container .ant-phone-code-form-container:hover + .ant-phone-form-wrapper {
  border-left-color: var(--input-hover-border-color);
}
.ant-phone-form-container .ant-phone-code-form-container:hover {
  border-right-color: var(--input-hover-border-color);
}
.ant-phone-form-container .ant-phone-code-form-container:hover + .ant-phone-form-input-wrapper .ant-input-group .ant-input + .ant-input-group-addon {
  border-left: 0;
}
.ant-phone-form-container .ant-phone-code-form-container .ant-select-selection-search {
  display: none;
}
.ant-phone-form-container .ant-phone-code-form-container .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-phone-form-container .ant-phone-code-form-container .ant-select-arrow {
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
}
.ant-phone-form-container .ant-form-item .ant-phone-form-select {
  width: 70px;
}
.ant-phone-form-container .ant-form-item .ant-phone-form-select .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 !important;
  height: unset;
}
.ant-phone-form-container .ant-phone-form-select-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-phone-form-container .ant-phone-form-select-content-title {
  display: none;
}
.ant-phone-form-select-list {
  width: 200px !important;
}
.ant-phone-form-select-list .ant-phone-form-select-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ant-phone-form-select-list .ant-phone-form-select-content-title {
  color: var(--input-color);
}
