/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker-time-panel-column::after {
  content: unset !important;
}
.ant-picker-time-panel-column > .ant-picker-time-panel-column-panel-cell-selected .ant-picker-time-panel-column-panel-cell-inner {
  color: var(--text-color-inverse) !important;
}
.ant-picker-time-panel-column li.ant-picker-time-cell-selected .ant-picker-time-panel-column li-cell-inner {
  background: var(--primary-color);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--primary-color);
}
.ant-picker-time-suffix {
  color: var(--text-color);
}
.ant-time-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.ant-time-picker-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  width: 50%;
}
.ant-time-picker-colon {
  height: 100%;
  max-width: 5%;
  position: relative;
}
.ant-time-picker-colon::after {
  content: ':';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
.ant-time-picker-arrow-btn-up {
  transform: rotate(180deg);
}
.ant-time-picker-arrow-btn.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 26px;
}
.ant-time-picker-arrow-btn.ant-btn[disabled] {
  color: var(--disabled-color);
}
.ant-time-picker .ant-input.ant-time-picker-hours,
.ant-time-picker .ant-input.ant-time-picker-minutes {
  padding: calc(var(--input-height-base) / 2) 12px;
  height: 48px;
  width: 100%;
  text-align: center;
  pointer-events: none;
}
.ant-time-picker .ant-input.ant-time-picker-hours:hover,
.ant-time-picker .ant-input.ant-time-picker-minutes:hover,
.ant-time-picker .ant-input.ant-time-picker-hours:focus,
.ant-time-picker .ant-input.ant-time-picker-minutes:focus,
.ant-time-picker .ant-input.ant-time-picker-hours:active,
.ant-time-picker .ant-input.ant-time-picker-minutes:active {
  border-color: var(--border-color-base);
}
.ant-time-picker .ant-input.ant-time-picker-hours::-webkit-outer-spin-button,
.ant-time-picker .ant-input.ant-time-picker-minutes::-webkit-outer-spin-button,
.ant-time-picker .ant-input.ant-time-picker-hours::-webkit-inner-spin-button,
.ant-time-picker .ant-input.ant-time-picker-minutes::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-time-picker-period {
  position: relative;
}
.ant-time-picker-period-wrapper {
  max-width: 20%;
}
.ant-time-picker-period .ant-radio-button + span {
  line-height: 1;
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 26px;
  color: var(--input-color);
  padding: 0;
  text-align: center;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: 0;
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper:first-child {
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
  border-bottom: none;
  border-right-color: var(--border-color-base);
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper:last-child {
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
  border-top: none;
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper:last-child::before {
  content: unset;
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper-checked {
  background: var(--primary-color);
  color: var(--text-color-inverse);
  border-color: var(--primary-color);
}
.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper:hover:not(.ant-time-picker-period.ant-time-picker-period.ant-radio-button-wrapper-checked) {
  background: rgba(var(--primary-color-rgb), 0.5);
  color: var(--text-color-inverse);
  border-color: rgba(var(--primary-color-rgb), 0.5);
}
