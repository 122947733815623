/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-password-input.ant-form-item {
  margin-bottom: 24px;
}
.ant-password-input.ant-form-item .ant-form-item-explain {
  display: none;
}
.ant-password-strength-wrapper {
  margin: -16px auto 24px;
}
.ant-password-strength-descriptions.ant-list {
  text-align: left;
}
.ant-password-strength-indicators {
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  margin-bottom: 8px;
}
.ant-password-strength-indicator {
  height: 10px;
  width: 100%;
}
.ant-password-strength-indicator-none {
  background-color: lightgrey;
}
.ant-password-strength-indicator-1 {
  background-color: #fe940d;
}
.ant-password-strength-indicator-2 {
  background-color: #ffd908;
}
.ant-password-strength-indicator-3 {
  background-color: #cbe11d;
}
.ant-password-strength-indicator-4,
.ant-password-strength-indicator-5 {
  background-color: #6ecc3a;
}
.ant-password-strength-indicator:first-child {
  border-top-left-radius: var(--border-radius-base);
  border-bottom-left-radius: var(--border-radius-base);
}
.ant-password-strength-indicator:last-child {
  border-top-right-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
}
.ant-form-item.ant-input-phone {
  z-index: 2;
}
.ant-form-item.ant-input-phone .ant-form-item-label {
  left: 64px !important;
}
.ant-form-item.ant-input-phone .react-tel-input .ant-input {
  padding-left: 64px;
}
.ant-form-item.ant-input-phone .react-tel-input .form-control {
  width: 100%;
  line-height: 1.5715;
  height: unset;
}
.ant-form-item.ant-input-phone .react-tel-input .flag-dropdown {
  display: flex;
}
.ant-form-item.ant-input-phone .react-tel-input .flag-dropdown .selected-flag {
  width: 54px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}
.ant-form-item.ant-input-phone .react-tel-input .flag-dropdown .selected-flag .flag {
  position: relative;
  top: unset;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-form-item.ant-input-phone .react-tel-input .flag-dropdown .selected-flag .arrow {
  margin-top: 0;
  top: 0;
  left: 16px;
}
