/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout .ant-result-page {
  height: 100%;
}
.ant-drawer-body .ant-result {
  padding-left: 0;
  padding-right: 0;
}
