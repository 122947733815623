/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@media (max-width: 767px) {
  .ant-full-page-gallery-drawer .ant-drawer-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .ant-full-page-gallery-title {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.ant-full-page-gallery-item {
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 767px) {
  .ant-full-page-gallery-item-details {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 768px) {
  .ant-full-page-gallery-item-h {
    flex-direction: row;
    gap: 32px;
  }
  .ant-full-page-gallery-item-h-r {
    flex-direction: row-reverse;
  }
  .ant-full-page-gallery-item-h .ant-full-page-gallery-item-image {
    width: 60%;
  }
  .ant-full-page-gallery-item-h .ant-full-page-gallery-item-details {
    width: 40%;
  }
}
.ant-full-page-gallery-item .ant-typography {
  margin-bottom: 0;
}
.ant-full-page-gallery-item-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center center;
}
@media (min-width: 576px) {
  .ant-full-page-gallery-item-image {
    height: 450px;
  }
}
