/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  font-family: var(--btn-font-family);
  font-weight: var(--btn-font-weight);
  letter-spacing: 0.8px;
  line-height: var(--btn-font-size);
  transition: background-color 0.2s, color 0.2s, border 0.2s;
  background-color: var(--btn-default-bg);
  font-size: var(--btn-font-size);
  letter-spacing: 0.5px;
  text-transform: var(--btn-text-transform);
}
.ant-btn.ant-btn-icon {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.ant-btn.ant-btn-icon .anticon {
  margin: 0 auto;
}
.ant-btn.ant-btn-icon .anticon svg {
  width: 100%;
  height: 100%;
}
.ant-btn.ant-btn-icon:hover,
.ant-btn.ant-btn-icon:focus,
.ant-btn.ant-btn-icon:active {
  background: transparent;
  border: none;
  color: var(--primary-color);
}
.ant-btn-circle {
  padding-bottom: 0;
}
.ant-btn-circle svg {
  transform: translateY(2px);
}
.ant-btn.wide {
  padding-left: 75px;
  padding-right: 75px;
}
.ant-btn.ant-btn-link {
  border: none;
  border-color: transparent;
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: none;
  text-align: left;
  white-space: pre-line;
  font-weight: var(--font-size-base);
  text-transform: none;
}
.ant-btn.ant-btn-link .ant-typography {
  color: var(--primary-color);
}
.ant-btn.ant-btn-link.ant-btn-small {
  font-size: 12px;
}
.ant-btn.ant-btn-link-anchor {
  height: auto;
  font-weight: var(--font-weight-strong) !important;
  padding: 0;
}
.ant-btn.ant-btn-link-anchor * {
  text-decoration: underline;
}
.ant-btn.ant-btn-link:hover,
.ant-btn.ant-btn-link:active,
.ant-btn.ant-btn-link:focus {
  background-color: transparent;
}
.ant-btn.ant-btn-text {
  border: none;
  border-color: transparent;
  background-color: transparent;
  color: var(--text-color);
  box-shadow: none;
  text-align: left;
  white-space: pre-line;
  font-weight: var(--font-size-base);
  text-transform: none;
  padding: 0;
  height: auto;
}
.ant-btn.ant-btn-text .ant-typography {
  color: var(--text-color);
}
.ant-btn.ant-btn-text.ant-btn-small {
  font-size: 12px;
}
.ant-btn.ant-btn-text:hover,
.ant-btn.ant-btn-text:active,
.ant-btn.ant-btn-text:focus {
  background-color: transparent;
}
.ant-btn.ant-btn-text.ant-btn-theme-primary {
  color: var(--primary-color);
}
.ant-btn:not(.ant-btn-circle) {
  border-radius: var(--btn-border-radius);
}
.ant-btn:focus,
.ant-btn:hover {
  color: var(--btn-default-hover-color);
  background-color: var(--btn-default-hover-bg);
  border-color: var(--btn-default-hover-border);
}
.ant-btn.ant-btn-ghost {
  background-color: var(--btn-ghost-bg);
  border-color: var(--btn-ghost-border);
  color: var(--btn-ghost-color);
}
.ant-btn.ant-btn-ghost:focus,
.ant-btn.ant-btn-ghost:hover {
  background-color: var(--btn-ghost-hover-bg);
  border-color: var(--btn-ghost-hover-border);
  color: var(--btn-ghost-hover-color);
}
.ant-btn.ant-btn-primary:not([disabled]) {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-color);
  border-color: var(--btn-primary-border-color);
}
.ant-btn.ant-btn-primary:not([disabled]):focus,
.ant-btn.ant-btn-primary:not([disabled]):hover {
  background-color: var(--btn-primary-hover-bg);
  color: var(--btn-primary-hover-color);
  border-color: var(--btn-primary-hover-border-color);
}
.ant-btn.ant-btn-warning:not([disabled]) {
  background-color: var(--warning-color);
  border-color: var(--warning-color);
}
.ant-btn.ant-btn-warning:not([disabled]):focus,
.ant-btn.ant-btn-warning:not([disabled]):hover {
  color: rgba(var(text-color-inverse-rgb), 0.8);
  background-color: rgba(var(--warning-color-rgb), 0.8);
  border-color: rgba(var(--warning-color-rgb), 0.8);
}
.ant-btn.ant-btn-danger:not([disabled]) {
  background-color: var(--danger-color);
  border-color: var(--danger-color);
}
.ant-btn.ant-btn-danger:not([disabled]):focus,
.ant-btn.ant-btn-danger:not([disabled]):hover {
  color: rgba(var(--text-color-inverse-rgb), 0.8);
  background-color: rgba(var(--danger-color-rgb), 0.8);
  border-color: rgba(var(--danger-color-rgb), 0.8);
}
.ant-btn.ant-btn-success:not([disabled]) {
  background-color: var(--success-color);
  border-color: var(--success-color);
}
.ant-btn.ant-btn-success:not([disabled]):focus,
.ant-btn.ant-btn-success:not([disabled]):hover {
  color: rgba(var(--text-color-inverse-rgb), 0.8);
  background-color: rgba(var(--success-color-rgb), 0.8);
  border-color: rgba(var(--success-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-text:not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  background-color: transparent;
  border-color: var(--text-color);
  color: var(--text-color);
}
.ant-btn.ant-btn-theme-text:not([disabled]):not(.ant-btn-link):not(.ant-btn-text):focus,
.ant-btn.ant-btn-theme-text:not([disabled]):not(.ant-btn-link):not(.ant-btn-text):hover {
  color: rgba(var(--text-color-rgb), 0.8);
  background-color: transparent;
  border-color: rgba(var(--text-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-link,
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-text {
  color: var(--text-color);
  font-weight: var(--font-weight-base);
}
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-link:focus,
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-text:focus,
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-link:hover,
.ant-btn.ant-btn-theme-text:not([disabled]).ant-btn-text:hover {
  color: rgba(var(--text-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-error:not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  background-color: var(--error-color);
  border-color: var(--error-color);
}
.ant-btn.ant-btn-theme-error:not([disabled]):not(.ant-btn-link):not(.ant-btn-text):focus,
.ant-btn.ant-btn-theme-error:not([disabled]):not(.ant-btn-link):not(.ant-btn-text):hover {
  color: rgba(var(--text-color-inverse-rgb), 0.8);
  background-color: rgba(var(--error-color-rgb), 0.8);
  border-color: rgba(var(--error-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-link,
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-text {
  color: var(--error-color);
}
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-link:focus,
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-text:focus,
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-link:hover,
.ant-btn.ant-btn-theme-error:not([disabled]).ant-btn-text:hover {
  color: rgba(var(--error-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-text,
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-error {
  background-color: transparent;
  box-shadow: none;
}
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-text {
  color: var(--text-color);
}
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-text:focus,
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-text:hover {
  color: rgba(var(--text-color-rgb), 0.8);
  border-color: rgba(var(--text-color-rgb), 0.8);
}
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-error {
  color: var(--error-color);
}
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-error:focus,
.ant-btn.ant-btn-theme-outlined:not([disabled]).ant-btn-theme-error:hover {
  color: rgba(var(--error-color-rgb), 0.8);
  border-color: rgba(var(--error-color-rgb), 0.8);
}
.ant-input-search:hover .ant-input-search-button,
.ant-input-search:focus .ant-input-search-button {
  border-color: var(--input-hover-border-color);
}
.ant-input-search:hover .ant-input,
.ant-input-search:focus .ant-input {
  border-color: var(--input-hover-border-color);
}
.ant-input-search .ant-input-search-button {
  background: transparent;
  border-color: var(--input-border-color);
  border-left: 0;
}
.ant-input-search .ant-input-search-button:hover,
.ant-input-search .ant-input-search-button:focus {
  background: transparent;
}
.ant-input-search .ant-input {
  border-right: 0;
}
.ant-input-search .ant-input-group-addon {
  background: transparent;
}
.ant-input-search .ant-input-group-addon:hover,
.ant-input-search .ant-input-group-addon:focus {
  background: transparent;
}
