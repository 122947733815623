/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-checkable {
  width: 100%;
  margin: 0 !important;
  padding: 12px;
  border: 1px solid var(--input-border-color);
  cursor: pointer;
  transition: border 0.2s;
  background-color: var(--card-bg);
}
.ant-checkable.checked {
  background-color: var(--card-selected-bg);
  border-color: var(--colors-gray-3);
  box-shadow: var(--card-shadow);
}
.ant-checkable .ant-avatar {
  background-color: var(--border-color-base);
}
.ant-checkable .ant-radio-wrapper {
  margin: 0;
}
.ant-checkable .anticon svg {
  color: var(--primary-color);
}
