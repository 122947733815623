/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@keyframes slide-in-keyframes {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out-keyframes {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(calc(-100% - 100px));
  }
  50% {
    transform: translateX(calc(-200% - 200px));
  }
  100% {
    transform: translateX(calc(-300% - 300px));
  }
}
.multi-survey,
.ant-multi-survey {
  max-width: 930px;
  text-align: left;
}
.multi-survey-items,
.ant-multi-survey-items {
  display: flex;
  overflow: hidden;
  position: relative;
}
.multi-survey-item,
.ant-multi-survey-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
}
.multi-survey-item-active,
.ant-multi-survey-item-active {
  visibility: visible;
  opacity: 1;
  animation: slide-in 0.3s;
}
.multi-survey .slick-slide,
.ant-multi-survey .slick-slide {
  margin: 0;
}
.multi-survey-dots,
.ant-multi-survey-dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.multi-survey-dots-item,
.ant-multi-survey-dots-item {
  position: relative;
  background-color: var(--text-color);
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  border-radius: calc(8px / 2);
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
  opacity: 0.3;
}
.multi-survey-dots-item-active,
.ant-multi-survey-dots-item-active {
  opacity: 1;
  width: 24px;
}
.multi-survey-button,
.ant-multi-survey-button {
  width: 100%;
  max-width: 400px;
  margin: 32px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
