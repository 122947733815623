/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card-badge {
  display: flex;
  width: 72px;
  height: 64px;
  padding: 8px 12px 12px;
  font-weight: 700;
  font-size: var(--badge-font-size);
  font-family: var(--badge-font-family);
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--border-radius-xs);
}
.ant-card-badge-responsive {
  min-width: 72px;
  min-height: 64px;
}
.ant-card-badge-primary {
  color: var(--card-badge-color);
  background-color: var(--card-badge-bg);
}
.ant-card-badge-primary .ant-typography {
  color: var(--card-badge-color);
}
.ant-card-badge-info {
  color: var(--text-color-inverse);
  background-color: var(--info-color);
}
.ant-card-badge-info .ant-typography {
  color: var(--card-badge-color);
}
.ant-card-badge-warning {
  color: var(--text-color-inverse);
  background-color: var(--warning-color);
}
.ant-card-badge-warning .ant-typography {
  color: var(--card-badge-color);
}
.ant-card-badge-danger {
  color: var(--text-color-inverse);
  background-color: var(--danger-color);
}
.ant-card-badge-danger .ant-typography {
  color: var(--card-badge-color);
}
