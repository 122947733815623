/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-custom-checkbox.ant-checkbox-wrapper {
  width: 100%;
  margin-left: 0;
}
.ant-custom-checkbox.ant-checkbox-wrapper .ant-checkbox + span {
  width: 100%;
}
