/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-compact-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ant-compact-group .ant-form-item.ant-group-item {
  margin-bottom: 10px;
}
.ant-compact-group .ant-form-item.ant-group-item-first:not(.ant-compact-group .ant-form-item.ant-group-item-last) .ant-input,
.ant-compact-group .ant-form-item.ant-group-item-first:not(.ant-compact-group .ant-form-item.ant-group-item-last) .ant-select-selector,
.ant-compact-group .ant-form-item.ant-group-item-first:not(.ant-compact-group .ant-form-item.ant-group-item-last) .ant-input-affix-wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector {
  border-radius: 0;
  border-left: none;
  position: relative;
  --border-color: var(--input-hover-border-color);
  --border-width: var(--border-width-base);
}
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input:hover::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector:hover::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input:focus::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector:focus::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input:hover::after,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector:hover::after,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input:focus::after,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector:focus::after {
  opacity: 1;
}
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input::after,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector::after {
  content: '';
  transition: opacity 0.2s;
  opacity: 0;
  position: absolute;
  top: 0;
  width: var(--border-width);
  height: 100%;
  background-color: var(--border-color);
}
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input::before,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector::before {
  left: 0;
}
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-input::after,
.ant-compact-group .ant-form-item.ant-group-item-middle .ant-select-selector::after {
  right: 0;
}
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) {
  --border-color: var(--input-hover-border-color);
  --border-width: var(--border-width-base);
}
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-select-selector,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input-affix-wrapper {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
}
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input:hover::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-select-selector:hover::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input-affix-wrapper:hover::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input:focus::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-select-selector:focus::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input-affix-wrapper:focus::before {
  opacity: 1;
}
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-select-selector::before,
.ant-compact-group .ant-form-item.ant-group-item-last:not(.ant-compact-group .ant-form-item.ant-group-item-first) .ant-input-affix-wrapper::before {
  content: '';
  transition: opacity 0.2s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--border-width);
  height: 100%;
  background-color: var(--border-color);
}
