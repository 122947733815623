/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#ppv .ant-debug-wrapper {
  position: fixed;
  right: 0;
  bottom: -1px;
  transform: translateY(100%);
  max-width: 500px;
  width: 100%;
  z-index: 1001;
  transition: transform 0.2s ease-in-out;
  background-color: white;
}
#ppv .ant-debug-wrapper .screenshots {
  display: flex;
  align-items: flex-start;
}
#ppv .ant-debug-wrapper .screenshots .screenshot-wrapper {
  height: 220px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
}
#ppv .ant-debug-wrapper .screenshots .screenshot-wrapper:nth-child(1) {
  width: 102.76px;
}
#ppv .ant-debug-wrapper .screenshots .screenshot-wrapper:nth-child(2) {
  width: 124.63px;
}
#ppv .ant-debug-wrapper .screenshots .screenshot-wrapper:nth-child(3) {
  width: 164.92px;
}
#ppv .ant-debug-wrapper .screenshots .ant-image img {
  height: 100%;
}
#ppv .ant-debug-wrapper-visible {
  transition: transform 0.2s ease-out;
  bottom: 0;
  transform: translateY(0);
}
#ppv .ant-debug-wrapper .debug-company-icon {
  width: 32px;
  height: 32px;
  background: rgba(87, 128, 110, 0.85);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 10px;
}
#ppv .ant-debug-wrapper .ant-card-body {
  height: 80vh;
  overflow: auto;
}
#ppv .ant-debug-wrapper .ant-debug-btn.ant-btn,
#ppv .ant-debug-wrapper .ant-debug-btn.ant-btn:not([disabled]) {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: rgba(87, 128, 110, 0.85) !important;
  color: white !important;
  border: 1px solid rgba(87, 128, 110, 0.85) !important;
  border-bottom: none;
  height: 32px;
  padding: 4px 12px;
  font-weight: 400;
}
#ppv .ant-debug-wrapper .ant-debug-color-list {
  padding-top: 12px;
  max-width: initial !important;
}
#ppv .ant-debug-wrapper .ant-debug-color-list .ant-row {
  margin-bottom: 0;
}
#ppv .ant-debug-wrapper .ant-debug-color-actions {
  position: relative;
}
#ppv .ant-debug-wrapper .ant-debug-color-actions-search .ant-input-search-button {
  background-color: rgba(87, 128, 110, 0.85) !important;
  border-color: #e8e7e9 !important;
}
#ppv .ant-debug-wrapper .ant-debug-color-actions-search .ant-input-search-button:hover,
#ppv .ant-debug-wrapper .ant-debug-color-actions-search .ant-input-search-button:focus,
#ppv .ant-debug-wrapper .ant-debug-color-actions-search .ant-input-search-button:active {
  background-color: rgba(97, 143, 123, 0.85) !important;
}
#ppv .ant-debug-wrapper .ant-debug-color-box {
  height: 20px;
  width: 20px;
  border: 1px solid #e8e7e9;
}
#ppv .ant-debug-wrapper .ant-debug-color-popover {
  position: absolute;
  z-index: 2;
  right: 0;
}
#ppv .ant-debug-wrapper .ant-debug-color-popover-top {
  bottom: calc(100% + 24px);
}
#ppv .ant-debug-wrapper .ant-debug-color-popover-bottom {
  top: 100%;
}
#ppv .ant-debug-wrapper-card {
  height: calc(100vh - 100px);
  overflow: auto;
  background-color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#ppv .ant-debug-wrapper-card .ant-typography {
  color: #212224;
}
#ppv .ant-debug-wrapper-card .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}
#ppv .ant-debug-wrapper-card .anticon {
  vertical-align: middle;
}
#ppv .ant-debug-wrapper-card .ant-list-item:first-child {
  padding-top: 0;
}
#ppv .ant-debug-wrapper-card .ant-tabs {
  overflow: unset;
}
#ppv .ant-debug-wrapper-card .ant-tabs > .ant-tabs-nav {
  overflow: unset;
  background-color: white !important;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 0;
}
#ppv .ant-debug-wrapper-card .ant-tabs-content-holder {
  padding-top: 24px;
}
#ppv .ant-debug-wrapper-card .ant-tabs-ink-bar {
  background-color: rgba(87, 128, 110, 0.85) !important;
}
#ppv .ant-debug-wrapper-card .ant-tabs-tab .ant-tabs-tab-btn {
  color: rgba(87, 128, 110, 0.85);
}
#ppv .ant-debug-wrapper-card .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: rgba(87, 128, 110, 0.85) !important;
}
#ppv .ant-debug-wrapper-card .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(87, 128, 110, 0.85) !important;
}
#ppv .ant-debug-wrapper-item {
  z-index: 0;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
}
#ppv .ant-debug-wrapper-item .ant-input {
  color: #212224;
}
#ppv .ant-debug-wrapper-item-active {
  z-index: 1;
}
#ppv .ant-debug-wrapper-item .block-picker {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px !important;
}
