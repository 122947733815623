/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-focused,
.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled) .ant-select-selector,
.ant-select :not(-disabled):hover -selector,
.ant-select-focused -selector,
.ant-select-open -selector {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-form-large .ant-form-item-label > label {
  height: auto !important;
}
.ant-form-large .ant-form-item-control-input {
  min-height: unset !important;
}
.ant-form-item {
  position: relative;
  z-index: 1;
  margin: 0;
}
.ant-form-item-control-input .ant-input-group-addon .ant-form-item {
  margin-bottom: 0;
}
.ant-form-item-control-input .ant-input-group-addon .ant-form-item .ant-select-arrow {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.ant-form-item-canvas canvas {
  background-color: var(--input-bg);
  width: 100%;
  height: 150px;
  border: var(--border-width-base) solid var(--border-color-base);
}
.ant-form-item-upload .ant-form-item-label,
.ant-form-item-upload .ant-form-item-control {
  flex: 1 1 100% !important;
  text-align: left;
}
.ant-form-item-checkbox .ant-form-item-control,
.ant-form-item-radio .ant-form-item-control,
.ant-form-item-yesno .ant-form-item-control {
  margin-top: 6px;
  margin-bottom: 0 !important;
}
.ant-form-item:not(.ant-form-item-checkbox):not(.ant-form-item-radio).ant-form-item:not(.ant-form-item-yesno) .ant-form-item-control {
  margin-bottom: 0 !important;
}
.ant-form-item::after {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  transform: translateY(calc(-50% - 10px));
  content: '';
}
.ant-form-item-checkbox .ant-form-item-label,
.ant-form-item-radio .ant-form-item-label,
.ant-form-item-yesno .ant-form-item-label {
  width: 100%;
  text-align: left;
}
.ant-form-item-checkbox .ant-form-item-explain,
.ant-form-item-radio .ant-form-item-explain,
.ant-form-item-yesno .ant-form-item-explain {
  padding-top: 25px;
  padding-left: 0;
}
.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 16px;
  padding: 4px;
  line-height: 1;
  color: var(--input-label-color);
}
.ant-form-item .ant-form-item-label > label::after {
  content: unset;
}
.ant-form-item:not(.ant-form-item-checkbox):not(.ant-form-item-radio):not(.ant-form-item-yesno):not(.ant-form-item-upload) .ant-form-item-label {
  position: absolute;
  top: 4px;
  left: 8px;
  right: 4px;
  z-index: 2;
  min-height: 18px;
  line-height: 16px !important;
  background-color: var(--input-bg);
  text-align: left;
  word-wrap: break-word;
  overflow: visible;
  padding-bottom: 0;
}
.ant-form .ant-item-bordered {
  border-bottom: 1px solid var(--btn-disable-border);
}
.ant-form .ant-survey-section-row {
  margin-top: 32px;
}
.ant-form .ant-survey-section-row:first-child {
  margin-top: 0;
}
.ant-form .ant-radio-group .ant-radio-button-wrapper {
  padding: 0 16px;
  background-color: var(--input-bg);
  color: var(--input-color);
}
.ant-form .ant-radio-group .ant-radio-button-wrapper-checked {
  background-color: var(--checkbox-check-bg);
  color: var(--checkbox-check-color);
}
.ant-select-selector,
.ant:not(.ant-select-customize-input)-selector {
  background-color: var(--input-bg);
  border-color: var(--border-color-base);
}
