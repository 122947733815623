/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-routing-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-routing-number .ant-input-suffix {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
