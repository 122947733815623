/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification-has-click {
  cursor: pointer;
}
.ant-notification-notice-info {
  background: var(--toast-info-bg);
}
.ant-notification-notice-info .ant-notification-notice-icon-info {
  color: var(--toast-info-icon-color);
}
.ant-notification-notice-info .ant-notification-notice-message {
  color: var(--toast-info-title-color);
}
.ant-notification-notice-info .ant-notification-notice-description {
  color: var(--toast-info-message-color);
}
.ant-notification-notice-success {
  background: var(--toast-success-bg);
}
.ant-notification-notice-success .ant-notification-notice-icon-success {
  color: var(--toast-success-icon-color);
}
.ant-notification-notice-success .ant-notification-notice-message {
  color: var(--toast-success-title-color);
}
.ant-notification-notice-success .ant-notification-notice-description {
  color: var(--toast-success-message-color);
}
.ant-notification-notice-error {
  background: var(--toast-error-bg);
}
.ant-notification-notice-error .ant-notification-notice-icon-error {
  color: var(--toast-error-icon-color);
}
.ant-notification-notice-error .ant-notification-notice-message {
  color: var(--toast-error-title-color);
}
.ant-notification-notice-error .ant-notification-notice-description {
  color: var(--toast-error-message-color);
}
.ant-notification-notice-warning {
  background: var(--toast-warning-bg);
}
.ant-notification-notice-warning .ant-notification-notice-icon-warning {
  color: var(--toast-warning-icon-color);
}
.ant-notification-notice-warning .ant-notification-notice-message {
  color: var(--toast-warning-title-color);
}
.ant-notification-notice-warning .ant-notification-notice-description {
  color: var(--toast-warning-message-color);
}
