/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-logo {
  cursor: pointer;
  max-width: 300px;
}
.ant-logo-icon,
.ant-logo-text,
.ant-logo .ant-image {
  height: 100%;
}
.ant-logo .ant-image {
  display: flex;
  align-items: center;
}
.ant-logo-icon.ant-logo-icon.ant-image-img,
.ant-logo-icon.ant-logo-text.ant-image-img,
.ant-logo-text.ant-logo-icon.ant-image-img,
.ant-logo-text.ant-logo-text.ant-image-img {
  object-fit: contain;
}
.ant-logo-icon.ant-logo-icon.ant-image-img {
  width: auto;
}
.ant-logo-text.ant-logo-text.ant-image-img {
  display: flex;
  align-items: center;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
.ant-logo .anticon + span {
  opacity: 1;
}
.ant-logo .ant-space-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.ant-logo .ant-space-item > * {
  height: 100%;
}
