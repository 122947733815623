/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-survey-form .ant-survey-title {
  text-transform: capitalize;
  font-family: var(--font-family-secondary);
}
.ant-survey-form .ant-form-large .ant-form-item-control-input {
  min-height: 24px;
}
.ant-survey-form .ant-form-item.switch .ant-form-item-control {
  margin-top: 0;
}
.ant-survey-form .ant-row.ant-form-item {
  margin-bottom: 8px;
}
.ant-survey .ant-col:first-of-type .ant-survey-section {
  margin-top: 0;
}
.ant-survey .ant-survey-section {
  margin-top: 60px;
}
.ant-survey-upload .ant-upload-list-item {
  padding: 20px;
  border: 1px solid var(--info-color);
}
.ant-survey-upload .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
