/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-google-maps-map {
  position: relative;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: var(--border-radius-base);
}
.ant-google-maps-marker {
  position: absolute;
  transform: translate(0, -50%);
}
.ant-google-maps-marker-text {
  background-color: white;
  min-width: 150px;
  padding: 12px 24px;
  border-radius: 8px;
}
