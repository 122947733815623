/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dark-bg {
  background-color: var(--text-color);
}
.ant-dark-bg * {
  color: var(--text-color-inverse);
}
.ant-page-layout {
  flex-direction: column !important;
}
.ant-page-layout .ant-layout.inverse * {
  color: var(--text-color-inverse);
}
.ant-page-layout .ant-layout-content,
.ant-page-layout .ant-layout-footer {
  padding: 24px;
}
@media (min-width: 768px) {
  .ant-page-layout .ant-layout-content,
  .ant-page-layout .ant-layout-footer {
    padding: 0 40px 40px;
  }
}
.ant-page-layout .ant-layout-footer {
  z-index: 2;
  width: 100%;
  padding: 40px;
  border-top: 1px solid var(--border-color-base);
}
@media (max-width: 991px) {
  .ant-page-layout .ant-layout-footer {
    height: auto;
  }
}
.ant-page-layout .ant-layout.ant-page-layout .ant-layout-has-sider > .ant-layout,
.ant-page-layout .ant-layout.ant-page-layout .ant-layout-has-sider > .ant-layout-content {
  width: 100%;
}
.ant-page-layout .ant-layout-content {
  width: 100% !important;
  height: fit-content;
}
.ant-page-layout .ant-layout-header,
.ant-page-layout .ant-layout-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}
.ant-page-layout .ant-layout-header .ant-logo,
.ant-page-layout .ant-layout-footer .ant-logo {
  color: var(--text-color-secondary);
}
.ant-page-layout .ant-layout-header {
  padding: 0 24px;
}
@media (min-width: 768px) {
  .ant-page-layout .ant-layout-header {
    padding: 20px 40px;
    min-height: var(--layout-header-height);
  }
}
.ant-page-layout .ant-layout .ant-breadcrumb {
  font-weight: 500;
  font-family: var(--breadcrumb-font-family);
}
.ant-page-layout .ant-layout .ant-page-sider {
  padding: 48px 40px 24px 40px;
  border-left: 1px solid var(--border-color-base);
}
.ant-page-layout .ant-layout .ant-page-footer {
  position: relative;
  z-index: 1;
  height: auto;
  border-top: 1px solid var(--border-color-base);
}
.ant-page-layout.ant-layout-content {
  width: auto !important;
}
.ant-page-layout .ant-page-header-heading-title {
  white-space: break-spaces;
}
@media (max-width: 1199px) {
  .ant-page-layout .ant-layout-header {
    border-bottom: 0;
  }
}
