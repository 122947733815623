/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card-checkable {
  width: 100%;
  background-color: var(--card-bg);
}
.ant-card-checkable.ant-card-checkable-checked:not(.ant-card-checkable.ant-card-checkable-with-radio) {
  background-color: var(--card-selected-bg);
  border-color: var(--colors-gray-3);
  box-shadow: var(--card-shadow);
}
.ant-card-checkable.ant-card-checkable-disabled:not(.ant-card-checkable-checked) {
  opacity: 0.5;
}
.ant-card-checkable.ant-card-checkable-with-radio.ant-card-checkable-checked {
  border-color: var(--primary-color);
}
.ant-card-checkable-radio {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
}
.ant-card-checkable-radio.ant-card-checkable-radio:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
}
.ant-card-checkable-radio-selected.ant-card-checkable-radio-selected:before {
  background-color: var(--radio-dot-color);
}
