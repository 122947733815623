/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  width: 100%;
  padding: 0;
}
.ant-page-header .ant-page-header-heading-extra {
  margin-top: 24px;
}
.ant-page-header-back {
  margin-right: 0px !important;
  margin-left: -15px;
}
.ant-page-header-back-button {
  color: var(--text-color);
}
.ant-page-header-back-button:visited,
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: var(--text-color);
}
.ant-page-header.has-breadcrumb .ant-breadcrumb + .ant-page-header-heading {
  margin: 0;
}
.ant-page-header.has-breadcrumb .ant-breadcrumb-link {
  font-family: var(--breadcrumb-font-family);
  text-transform: capitalize;
}
.ant-page-header.has-breadcrumb .ant-breadcrumb-link a {
  color: var(--breadcrumb-link-color);
}
.ant-page-header.has-breadcrumb .ant-breadcrumb-link a:hover {
  color: var(--breadcrumb-link-color-hover);
}
.ant-page-header.has-breadcrumb .ant-breadcrumb-link .ant-typography {
  color: var(--breadcrumb-last-item-color);
}
@media (max-width: 767px) {
  .ant-page-header-heading.ant-heading-long-left {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .ant-page-header-heading.ant-heading-long-extra .ant-space-item:first-child,
  .ant-page-header-heading.ant-heading-long-extra .ant-select {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ant-page-header-heading.ant-heading-long-extra {
    width: 100%;
  }
}
.ant-page-header .ant-page-header-heading-title {
  margin-top: 0;
  margin-right: 0;
  overflow: visible;
  text-overflow: unset;
}
.ant-page-header .ant-page-header-heading-title h1 {
  font-weight: var(--page-header-heading-font-weight);
  margin-top: 0;
  font-family: var(--page-header-heading-font-family);
  line-height: 1;
  text-transform: var(--heading-transform);
}
.ant-page-header .ant-page-header-heading-left {
  margin: 0;
  overflow: visible;
  margin-top: 10px;
}
