/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-wallet-card {
  cursor: pointer;
  width: 100%;
}
.ant-wallet-card .ant-avatar {
  transition: opacity 0.35s;
  padding: 25px 5px;
  opacity: 1;
}
.ant-wallet-card .ant-tag {
  pointer-events: none;
}
.ant-wallet-card .ant-card-meta-detail {
  width: 100%;
}
.ant-wallet-card .ant-card-meta-detail .ant-card-meta-title {
  margin-bottom: 0;
}
.ant-wallet-card .ant-payment-brand svg path {
  transition: fill 0.35s;
}
.ant-wallet-card:hover .ant-avatar {
  opacity: 0.75;
}
.ant-wallet-card-checkable {
  cursor: pointer;
  padding: 12px !important;
  border: 1px solid var(--border-color-base);
  margin-bottom: 10px;
  background-color: var(--component-background);
  opacity: 0.75;
  transition: opacity 0.3s;
}
.ant-wallet-card-checkable:hover {
  opacity: 1;
}
.ant-wallet-card-checked {
  border: 1px solid var(--primary-color) !important;
  opacity: 1;
}
