/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-payment-brand {
  width: 100%;
  line-height: 1;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  padding: 0 4px;
}
.ant-payment-brand div {
  height: inherit;
}
.ant-payment-brand svg {
  height: 100%;
  width: 100%;
  fill: none;
  display: flex;
}
.ant-payment-brand-white svg path {
  fill: #ffffff !important;
}
.ant-payment-brand-list {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.ant-payment-brand-list .ant-payment-brand {
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
