/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.anticon {
  color: var(--icon-color);
  line-height: 1;
}
.anticon.anticon-success {
  color: var(--success-color);
}
.anticon.anticon-primary {
  color: var(--primary-color);
}
.anticon.anticon-warning {
  color: var(--warning-color);
}
.anticon.anticon-danger {
  color: var(--danger-color);
}
.anticon.anticon-error {
  color: var(--error-color);
}
.anticon.anticon-info {
  color: var(--info-color);
}
.anticon.anticon-white {
  color: var(--white);
}
.anticon.anticon-secondary {
  color: var(--text-color-secondary);
}
.anticon a,
.anticon a:hover {
  color: var(--text-color);
}
.anticon.anticon.ant-ivy-icon-success {
  color: var(--success-color);
}
.anticon.anticon.ant-ivy-icon-primary {
  color: var(--primary-color);
}
.anticon.anticon.ant-ivy-icon-warning {
  color: var(--warning-color);
}
.anticon.anticon.ant-ivy-icon-danger {
  color: var(--danger-color);
}
.anticon.anticon.ant-ivy-icon-error {
  color: var(--error-color);
}
.anticon.anticon.ant-ivy-icon-info {
  color: var(--info-color);
}
.anticon.anticon.ant-ivy-icon-white {
  color: var(--white);
}
.anticon.anticon.ant-ivy-icon-default {
  color: var(--text-color);
}
.anticon.anticon.ant-ivy-icon-secondary {
  color: var(--text-color-secondary);
}
.anticon.anticon.ant-ivy-icon-background-primary {
  background-color: var(--primary-color);
}
.anticon.anticon.ant-ivy-icon-background-warning {
  background-color: var(--warning-color);
}
.anticon.anticon.ant-ivy-icon-background-danger {
  background-color: var(--danger-color);
}
.anticon.anticon.ant-ivy-icon-background-error {
  background-color: var(--error-color);
}
.anticon.anticon.ant-ivy-icon-background-info {
  background-color: var(--info-color);
}
.anticon.anticon.ant-ivy-icon-background-light {
  background-color: var(--avatar-bg);
}
.anticon.anticon.ant-ivy-icon-background-dark {
  background-color: var(--text-color-secondary);
}
.anticon.anticon.ant-ivy-icon-background-none {
  background-color: transparent;
}
