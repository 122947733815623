/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.ant-image-placeholder div:not(.ant-spin):not(.ant-section-spinner) {
  width: 100%;
  height: 100%;
}
.ant-image-placeholder div:not(.ant-spin):not(.ant-section-spinner) svg {
  width: 100%;
  height: 100%;
}
.ant-image-preview-operations {
  display: none !important;
}
