/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-autocomplete-wrapper .ant-form-item-control {
  min-width: 250px;
}
.ant-autocomplete-wrapper .ant-form-item-control-input {
  border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
}
.ant-autocomplete-wrapper .ant-form-item-label + .ant-form-item-control .ant-select-selection-search,
.ant-autocomplete-wrapper .ant-form-item-label + .ant-form-item-control .ant-select-single-selection-search {
  left: 0 !important;
}
.ant-autocomplete-wrapper .ant-form-item-label + .ant-form-item-control .ant-select-selection-search-input,
.ant-autocomplete-wrapper .ant-form-item-label + .ant-form-item-control .ant-select-single-selection-search-input {
  height: auto !important;
  color: var(--input-color);
  background-color: var(--input-bg);
  border: var(--border-width-base) var(--border-style-base) var(--input-border-color);
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
  padding: 24px 12px 12px !important;
}
.ant-list-action-search__wrapper {
  width: 250px;
  display: flex;
  flex-direction: row-reverse;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: var(--border-radius-base);
  transition-duration: 0.3s;
}
.ant-list-action-search__wrapper * {
  border: none !important;
}
.ant-list-action-search__wrapper:hover,
.ant-list-action-search__wrapper:focus,
.ant-list-action-search__wrapper-focused {
  border-color: var(--input-hover-border-color);
}
.ant-list-action-search__wrapper:hover *,
.ant-list-action-search__wrapper:focus *,
.ant-list-action-search__wrapper-focused * {
  border: none !important;
}
.ant-list-action-search__wrapper .ant-input-search-button {
  background-color: transparent;
}
.ant-list-action-search__wrapper .ant-input-search-button:hover,
.ant-list-action-search__wrapper .ant-input-search-button:focus {
  background-color: transparent;
}
.ant-list-action-search:hover + .ant-list-action-search__button,
.ant-list-action-search:active + .ant-list-action-search__button,
.ant-list-action-search:focus-within + .ant-list-action-search__button {
  border-color: var(--input-hover-border-color);
}
.ant-list-action-search .ant-select-selector {
  padding: 0 !important;
}
.ant-list-action-search .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 100%;
  border: none;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-list-action-search .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input:hover,
.ant-list-action-search .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input:active,
.ant-list-action-search .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input:focus {
  border-color: var(--input-border-color);
}
.ant-list-action-search .ant-select-selector .ant-select-selection-search.condensed {
  width: 0;
  padding-left: 0;
}
.ant-list-action-search__button {
  background: white;
  border: 1px solid var(--input-border-color);
  height: var(--search_btn_size);
  border-left: none !important;
  border-top-right-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
  cursor: pointer;
  padding-right: 22px;
}
.ant-list-action-search__button:hover {
  border-color: unset;
}
.ant-list-action-search__button + .ant-select-focused:not(.ant-select-disabled) {
  box-shadow: none;
}
.ant-list-action-search__button + .ant-select .ant-select-selection-search-input {
  cursor: text;
}
.header-search {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 20px;
}
.header-search::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--border-color-base);
  content: '';
}
.header-search__icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}
.header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.header-search .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.header-search .ant-select-focused:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.header-search__input {
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  line-height: 16px;
}
.header-search__input::placeholder {
  color: var(--text-color-secondary);
}
.header-search input {
  line-height: 48px !important;
}
