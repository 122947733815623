/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table {
  background-color: var(--table-bg);
}
.ant-table-thead > tr > th {
  background-color: var(--table-header-bg);
  color: var(--table-header-color);
}
.ant-table-thead > tr > th *,
.ant-table-thead > tr > th .ant-typography,
.ant-table-thead > tr > th .ant-typography-secondary {
  color: var(--table-header-color);
}
.ant-table-thead > tr > th:last-child {
  text-align: right;
}
.ant-table-thead > tr > th.ant-table-cell:before {
  content: unset !important;
}
.ant-table th.ant-table-column-sort {
  background: var(--table-header-selected-bg);
  color: var(--table-header-selected-color);
}
.ant-table-row {
  background-color: var(--table-row-bg);
  color: var(--table-row-color);
}
.ant-table-row:hover {
  background-color: var(--table-row-hover-bg);
  color: var(--table-row-hover-color);
}
.ant-table-row-expand-icon {
  width: 21px;
  height: 21px;
  line-height: 21px;
  border-radius: 50%;
  background-color: var(--table-expand-icon-bg);
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  background-color: var(--table-expand-icon-color);
}
.ant-table-row-expand-icon::before {
  top: 9px;
  left: 5px;
  width: 9px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 5px;
  left: 9px;
  width: 1px;
  height: 9px;
}
