/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-portal-sider-header-logo {
  display: flex;
}
.ant-portal-sider-header-logo-left {
  justify-content: start;
}
.ant-portal-sider-header-logo-center {
  justify-content: center;
}
.ant-portal-sider-header-logo-right {
  justify-content: end;
}
.ant-layout-sider-collapsed {
  justify-content: center;
  width: 112px;
}
.ant-layout-sider.inverse a,
.ant-layout-sider.inverse .ant-text-typography,
.ant-layout-sider.inverse h1,
.ant-layout-sider.inverse h2,
.ant-layout-sider.inverse h3,
.ant-layout-sider.inverse h4,
.ant-layout-sider.inverse h5,
.ant-layout-sider.inverse h6 {
  color: var(--text-color-inverse) !important;
}
.ant-layout-sider.fixed-left > .ant-layout-sider-children {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  background-color: var(--menu-bg);
}
.ant-layout-sider .ant-layout-header {
  align-content: center;
  line-height: 0;
  background: var(--menu-bg);
}
.ant-layout-sider .ant-sider-footer {
  width: inherit;
  padding: 0;
  background: var(--layout-footer-background);
  border-top: none;
}
@media (min-width: 768px) {
  .ant-layout-sider .ant-sider-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
  }
}
.ant-layout-sider .ant-sider-footer .ant-account-sider-card {
  display: block;
  height: var(--layout-footer-height);
  padding: 36px 28px;
  border-top: 1px solid var(--border-color-base);
}
.ant-layout-sider .ant-sider-footer .ant-account-sider-card .ant-card-meta-title,
.ant-layout-sider .ant-sider-footer .ant-account-sider-card .ant-card-meta-description {
  color: var(--menu-item-color);
}
/* Mobile Sider within Drawer */
.ant-drawer-mobile-sider .ant-drawer-header-no-title,
.ant-drawer-mobile-sider .ant-layout-sider-children {
  position: relative !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.ant-drawer-mobile-sider .ant-drawer-body {
  padding-bottom: 0 !important;
}
