/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel .slick-track {
  display: flex !important;
  align-items: stretch;
}
.ant-carousel .slick-list {
  margin: 0 -12px;
}
.ant-carousel .slick-slide {
  height: inherit;
  margin: 0 12px;
}
.ant-carousel-card .ant-carousel .slick-slide {
  height: auto;
}
.ant-carousel-card .ant-carousel .slick-slide > div,
.ant-carousel-card .ant-carousel .slick-slide > div > div {
  height: 100%;
}
.ant-carousel .slick-dots {
  z-index: 0;
}
.ant-carousel .slick-dots-bottom {
  position: static;
  display: flex;
  align-items: center;
  height: 30px;
  bottom: -40px;
  margin: 0;
}
.ant-carousel .slick-dots li button {
  width: 8px !important;
  height: 8px !important;
  border-radius: calc(8px / 2);
  background-color: var(--text-color);
}
.ant-carousel .slick-dots li.slick-active button {
  width: 20px !important;
  background-color: var(--text-color);
}
@media (max-width: 767px) {
  .ant-carousel-banner {
    margin-left: -24px;
    margin-right: -24px;
  }
}
.ant-carousel-banner,
.ant-carousel-card {
  position: relative;
  margin-bottom: 50px;
}
.ant-carousel-banner .ant-carousel img-dots,
.ant-carousel-card .ant-carousel img-dots,
.ant-carousel-banner .ant-carousel .slick-dots,
.ant-carousel-card .ant-carousel .slick-dots {
  z-index: 0;
}
.ant-carousel-banner .ant-carousel img-dots-bottom,
.ant-carousel-card .ant-carousel img-dots-bottom,
.ant-carousel-banner .ant-carousel .slick-dots-bottom,
.ant-carousel-card .ant-carousel .slick-dots-bottom {
  position: static;
  display: flex;
  align-items: center;
  height: 30px;
  bottom: -40px;
  margin: 0;
}
.ant-carousel-banner .ant-carousel img-dots li button,
.ant-carousel-card .ant-carousel img-dots li button,
.ant-carousel-banner .ant-carousel .slick-dots li button,
.ant-carousel-card .ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: calc(8px / 2);
  background-color: var(--text-color);
}
.ant-carousel-banner .ant-carousel img-dots li.slick-active button,
.ant-carousel-card .ant-carousel img-dots li.slick-active button,
.ant-carousel-banner .ant-carousel .slick-dots li.slick-active button,
.ant-carousel-card .ant-carousel .slick-dots li.slick-active button {
  width: 20px;
  background-color: var(--text-color);
}
.ant-carousel-banner.ant-carousel-banner-nav,
.ant-carousel-banner.ant-carousel-card-nav,
.ant-carousel-card.ant-carousel-banner-nav,
.ant-carousel-card.ant-carousel-card-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 30px;
  z-index: 1;
}
.ant-carousel-banner.ant-carousel-banner-nav-arrows-prev.ant-btn,
.ant-carousel-banner.ant-carousel-card-nav-arrows-prev.ant-btn,
.ant-carousel-card.ant-carousel-banner-nav-arrows-prev.ant-btn,
.ant-carousel-card.ant-carousel-card-nav-arrows-prev.ant-btn,
.ant-carousel-banner.ant-carousel-banner-nav-arrows-next.ant-btn,
.ant-carousel-banner.ant-carousel-card-nav-arrows-next.ant-btn,
.ant-carousel-card.ant-carousel-banner-nav-arrows-next.ant-btn,
.ant-carousel-card.ant-carousel-card-nav-arrows-next.ant-btn {
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.ant-carousel-banner.ant-carousel-banner-nav-arrows-prev.ant-btn[disabled],
.ant-carousel-banner.ant-carousel-card-nav-arrows-prev.ant-btn[disabled],
.ant-carousel-card.ant-carousel-banner-nav-arrows-prev.ant-btn[disabled],
.ant-carousel-card.ant-carousel-card-nav-arrows-prev.ant-btn[disabled],
.ant-carousel-banner.ant-carousel-banner-nav-arrows-next.ant-btn[disabled],
.ant-carousel-banner.ant-carousel-card-nav-arrows-next.ant-btn[disabled],
.ant-carousel-card.ant-carousel-banner-nav-arrows-next.ant-btn[disabled],
.ant-carousel-card.ant-carousel-card-nav-arrows-next.ant-btn[disabled] {
  background-color: transparent;
  opacity: 0.5;
}
.ant-carousel-banner.ant-carousel-banner-nav-arrows-prev.ant-btn:hover,
.ant-carousel-banner.ant-carousel-card-nav-arrows-prev.ant-btn:hover,
.ant-carousel-card.ant-carousel-banner-nav-arrows-prev.ant-btn:hover,
.ant-carousel-card.ant-carousel-card-nav-arrows-prev.ant-btn:hover,
.ant-carousel-banner.ant-carousel-banner-nav-arrows-next.ant-btn:hover,
.ant-carousel-banner.ant-carousel-card-nav-arrows-next.ant-btn:hover,
.ant-carousel-card.ant-carousel-banner-nav-arrows-next.ant-btn:hover,
.ant-carousel-card.ant-carousel-card-nav-arrows-next.ant-btn:hover,
.ant-carousel-banner.ant-carousel-banner-nav-arrows-prev.ant-btn:active,
.ant-carousel-banner.ant-carousel-card-nav-arrows-prev.ant-btn:active,
.ant-carousel-card.ant-carousel-banner-nav-arrows-prev.ant-btn:active,
.ant-carousel-card.ant-carousel-card-nav-arrows-prev.ant-btn:active,
.ant-carousel-banner.ant-carousel-banner-nav-arrows-next.ant-btn:active,
.ant-carousel-banner.ant-carousel-card-nav-arrows-next.ant-btn:active,
.ant-carousel-card.ant-carousel-banner-nav-arrows-next.ant-btn:active,
.ant-carousel-card.ant-carousel-card-nav-arrows-next.ant-btn:active {
  background-color: transparent;
}
.ant-carousel-banner {
  position: relative;
  overflow: hidden;
}
.ant-carousel-banner-with-nav {
  margin-bottom: 50px;
}
.ant-carousel-banner-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 30px;
  z-index: 1;
}
.ant-carousel-banner-nav-arrows-prev.ant-btn,
.ant-carousel-banner-nav-arrows-next.ant-btn {
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.ant-carousel-banner-nav-arrows-prev.ant-btn[disabled],
.ant-carousel-banner-nav-arrows-next.ant-btn[disabled] {
  opacity: 0.25;
  background-color: transparent;
}
.ant-carousel-banner-nav-arrows-prev.ant-btn:hover,
.ant-carousel-banner-nav-arrows-next.ant-btn:hover,
.ant-carousel-banner-nav-arrows-prev.ant-btn:active,
.ant-carousel-banner-nav-arrows-next.ant-btn:active {
  background-color: transparent;
}
.ant-carousel-banner .ant-carousel img-slide,
.ant-carousel-banner .ant-carousel .slick-slide {
  height: 100%;
  max-height: 450px;
  margin-left: 0;
  margin-right: 0;
}
.ant-carousel-banner .ant-carousel img-slide div,
.ant-carousel-banner .ant-carousel .slick-slide div {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
@media (min-width: 992px) {
  .ant-carousel-banner .ant-carousel img-slide div,
  .ant-carousel-banner .ant-carousel .slick-slide div {
    min-height: 450px;
  }
}
.ant-carousel-banner .ant-carousel img-slide img,
.ant-carousel-banner .ant-carousel .slick-slide img {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
}
.ant-carousel-items {
  position: relative;
}
.ant-carousel-items-with-nav {
  margin-bottom: 50px;
}
.ant-carousel-items .ant-carousel img-slide,
.ant-carousel-items .ant-carousel .slick-slide {
  height: 100%;
}
.ant-carousel-items .ant-carousel img-slide > div,
.ant-carousel-items .ant-carousel .slick-slide > div {
  width: 100%;
  height: 100%;
}
