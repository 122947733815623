/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-public-view-container {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 767px) {
  .ant-public-view-container {
    padding: 24px 0;
  }
  .ant-public-view-container .ant-split-view {
    background-color: transparent;
  }
}
@media (min-width: 768px) {
  .ant-public-left-view-row {
    height: calc(100vh - 40px);
  }
}
@media (min-width: 768px) {
  .ant-public-left-view-container .ant-portal-navigation-header-link {
    margin-top: 40px;
  }
}
.ant-public-right-view-container {
  align-items: center;
  justify-content: center;
  display: flex !important;
  padding: 100px 0;
}
@media (max-width: 767px) {
  .ant-public-right-view-container {
    padding: 0;
    flex: 1 1 100% !important;
  }
}
.ant-public-right-view-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  .ant-public-right-view-content {
    height: 100%;
  }
}
.ant-public-right-view-content > * {
  width: 100%;
}
@media (max-width: 767px) {
  .ant-public-right-view-content {
    background-color: rgba(var(--body-background-rgb), var(--card-blur-bg-transparency));
    padding: 32px 0;
    backdrop-filter: blur(10px);
  }
}
.ant-slogan-carousel {
  overflow: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-slogan-carousel-item {
  height: 100%;
}
.ant-slogan-carousel-item-content {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.ant-slogan-carousel-item-content-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 200%);
  z-index: 0;
}
.ant-slogan-carousel-item-text {
  z-index: 1;
  max-width: 493px;
}
.ant-slogan-carousel .ant-carousel-items {
  height: 100%;
}
.ant-slogan-carousel .ant-carousel-items .ant-carousel,
.ant-slogan-carousel .ant-carousel-items .slick-slider,
.ant-slogan-carousel .ant-carousel-items .slick-slide,
.ant-slogan-carousel .ant-carousel-items .slick-track,
.ant-slogan-carousel .ant-carousel-items .slick-list {
  height: 100%;
}
.ant-slogan-carousel .ant-carousel-items .ant-carousel .slick-slide {
  max-height: none;
  position: relative;
  margin: 0;
}
