/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-transactions-table .ant-table-expanded-row > .ant-table-cell {
  background-color: transparent;
}
.ant-transactions-table .ant-table-cell {
  vertical-align: top;
}
.ant-transactions-table .ant-descriptions-bordered .ant-descriptions-row:not(:last-child) .ant-descriptions-item-label,
.ant-transactions-table .ant-descriptions-bordered .ant-descriptions-row:not(:last-child) .ant-descriptions-item-content {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transactions-table .ant-descriptions-bordered .ant-descriptions-row th:empty {
  display: none;
}
