/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography.ant-typography-clickable {
  text-decoration: underline;
  cursor: pointer;
  color: var(--link-color);
  transition: color 0.3s;
}
.ant-typography.ant-typography-clickable:hover,
.ant-typography.ant-typography-clickable:active,
.ant-typography.ant-typography-clickable:focus {
  color: var(--link-hover-color);
}
