/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-section-spinner {
  width: 100%;
  height: 100%;
}
.ant-section-spinner .ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-spin {
  color: var(--primary-color);
}
.ant-spin-dot-item {
  background-color: var(--primary-color);
}
.ant-overlay-spinner-loading {
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
.ant-overlay-spinner-loading-full::before {
  content: '';
  position: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.ant-overlay-spinner-loading .ant-spin-spinning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: transparent;
}
.ant-overlay-spinner-loading .ant-spin-spinning + * {
  opacity: 0.3;
  cursor: not-allowed;
}
.ant-overlay-spinner-loading .ant-spin-spinning + * * {
  pointer-events: none;
}
.ant-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-fullscreen-loader::before {
  content: '';
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.ant-fullscreen-loader-container {
  z-index: 5;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
#ppv .mask-loader {
  width: 100vw;
  height: 100vh;
}
#ppv .mask-loader .ant-drawer-content {
  background: unset;
  background-color: unset;
}
