/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-gallery-modal {
  max-width: 1100px !important;
  width: 100% !important;
}
.ant-gallery-modal .ant-modal-body {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .ant-gallery-modal .ant-modal-body {
    padding: 60px 120px;
  }
}
.ant-gallery-modal-container .ant-gallery-main {
  height: calc((630px - 50px) * 0.8);
}
.ant-gallery-modal-container .ant-gallery-items {
  height: calc((630px - 50px) * 0.2);
}
.ant-gallery-modal-container .ant-gallery-item:first-child {
  flex: 1 1 0;
}
.ant-gallery-nav-container {
  height: 50px;
}
.ant-gallery-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-flow: column;
  gap: 4px;
  overflow: hidden;
}
.ant-gallery-container .ant-carousel {
  margin: 0 -8px;
}
.ant-gallery-container .ant-carousel .slick-track {
  flex-flow: row;
  gap: 8px;
}
.ant-gallery-container .ant-carousel .slick-slide {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  height: auto;
}
.ant-gallery-container .ant-carousel .slick-slide div {
  height: 100%;
}
.ant-gallery-container .ant-carousel .slick-slide .ant-gallery-item {
  height: 100%;
}
.ant-gallery-container .ant-carousel .slick-dots-top {
  top: -28px;
}
.ant-gallery-main {
  width: 100%;
  height: 70%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.ant-gallery-main .ant-gallery-item {
  flex: 1 1 0 !important;
}
.ant-gallery-items {
  width: 100%;
  height: 30%;
  overflow: hidden;
}
.ant-gallery-item {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 0;
  transition: opacity 0.3s;
  max-width: 100%;
  pointer-events: all;
}
.ant-gallery-item-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  pointer-events: none;
}
.ant-gallery-item-preview-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.ant-gallery-item:hover .ant-gallery-item-preview {
  opacity: 1;
}
.ant-gallery-item-active {
  opacity: 0.5;
}
.ant-gallery-item:first-child {
  flex: 2 1 0;
}
.ant-gallery-item-click {
  cursor: pointer;
}
.ant-gallery-item img.ant-gallery-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 0;
}
.ant-gallery-item-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
}
.ant-gallery-item-title-text {
  position: relative;
  z-index: 1;
  margin-bottom: 0 !important;
}
.ant-gallery-item-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--body-background-rgb), 0.65);
  backdrop-filter: blur(10px);
}
