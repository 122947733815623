/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.infinite-scroll-component {
  overflow: initial !important;
}
.card-grid .ant-pagination {
  text-align: left;
}
.card-grid .ant-pagination-options {
  position: absolute;
  right: 0;
}
.ant-list-pagination {
  text-align: left;
}
.ant-pagination-item-active,
.ant-pagination-item:hover {
  border-color: var(--primary-color);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a {
  color: var(--primary-color);
}
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.ant-table-clickable .ant-table-row {
  cursor: pointer;
}
.ant-list-vertical .ant-list-item-meta-title,
.ant-list-item-meta-title {
  color: var(--text-color);
  font-weight: bold;
}
.ant-list-vertical .ant-list-item-meta-description,
.ant-list-item-meta-description {
  color: var(--text-color-secondary);
}
.ant-list-vertical .ant-list-item-meta-description .ant-typography,
.ant-list-item-meta-description .ant-typography {
  word-break: break-all;
}
.ant-list-vertical .ant-list-item-meta-bordered,
.ant-list-item-meta-bordered {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-base);
}
.ant-list-vertical .ant-list-item-meta:last-child,
.ant-list-item-meta:last-child {
  border-bottom: none;
}
.ant-list-vertical .ant-list-item-meta-click,
.ant-list-item-meta-click {
  cursor: pointer;
}
.ant-list-vertical .ant-list-item-bordered,
.ant-list-item-bordered {
  border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color-base);
}
.ant-list-vertical .ant-list-item-bordered .ant-list-item-meta,
.ant-list-item-bordered .ant-list-item-meta {
  margin-bottom: 0;
}
.ant-list-vertical .ant-list-item-with-click,
.ant-list-item-with-click {
  cursor: pointer;
  pointer-events: all;
}
.ant-list-vertical .ant-list-item-with-click *,
.ant-list-item-with-click * {
  pointer-events: none;
}
.ant-list-vertical .ant-list-item-with-click .ant-list-item-meta-avatar,
.ant-list-item-with-click .ant-list-item-meta-avatar {
  transition: opacity 0.3s;
  opacity: 0.8;
}
.ant-list-vertical .ant-list-item-with-click:hover .ant-list-item-meta-avatar,
.ant-list-item-with-click:hover .ant-list-item-meta-avatar {
  opacity: 1;
}
.pv-list-item-meta-title,
.pv-list-item-meta-title .ant-typography {
  font-family: var(--list-item-meta-title-font-family);
  color: var(--list-item-meta-title-color);
  font-weight: bold;
  margin-bottom: 0;
}
.pv-list-item-meta-description,
.pv-list-item-meta-description .ant-typography {
  font-family: var(--list-item-meta-description-font-family);
  color: var(--list-item-meta-description-color);
}
