/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker {
  width: 100%;
  border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
  border-radius: var(--border-radius-base);
  height: var(--input-height-base);
  background-color: var(--input-bg);
  padding-top: 0;
  padding-bottom: 0;
}
.ant-picker-input > input {
  color: var(--input-color);
}
.ant-picker-panel-container {
  background-color: var(--date-picker-bg);
}
.ant-picker-header-view button {
  color: var(--date-picker-heading-color);
}
.ant-picker-header-view button:hover,
.ant-picker-header-view button:focus,
.ant-picker-header-view button:active {
  color: var(--primary-color);
}
.ant-picker-cell-in-view.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
  --in-range-bg: var(--primary-color-rgb);
}
.ant-picker-cell-in-view.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: rgba(var(--in-range-bg), 0.3);
}
.ant-picker-cell-in-view.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-in-view.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range-hover::before {
  background-color: rgba(var(--in-range-bg), 0.5);
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover::after {
  border-top-color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--primary-color);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--border-color-inverse);
  border-radius: 50%;
}
.ant-picker-cell.ant-picker-cell-inner {
  border-radius: 50%;
}
.ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--primary-color);
}
.ant-picker-content {
  font-weight: 700;
}
.ant-picker-content th {
  color: var(--date-picker-heading-color);
  font-weight: inherit;
}
.ant-picker-content td {
  color: var(--date-picker-cell-color);
  font-weight: inherit;
}
.ant-picker-suffix {
  color: var(--text-color) !important;
}
.ant-picker-today-btn {
  color: var(--primary-color);
}
