/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-link {
  color: var(--link-color);
}
.ant-link-underline {
  text-decoration: underline;
}
.ant-link-strong {
  font-weight: bolder;
}
.ant-link:hover {
  color: var(--link-hover-color);
}
.ant-link :active {
  color: var(--link-active-color);
}
