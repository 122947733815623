/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-survey-section-row .ant-row {
  margin-bottom: 0;
}
.ant-survey-section-row + .ant-survey-section-row {
  margin-top: 24px;
}
.ant-form-item-checkbox .ant-form-item-control {
  min-width: unset;
}
.ant-form-item-radio,
.ant-form-item-checkbox {
  flex-flow: column;
}
.ant-form-item-radio .ant-tag,
.ant-form-item-checkbox .ant-tag {
  height: unset;
  line-height: 20px;
  white-space: normal;
}
.ant-form-item .ant-radio-wrapper::after {
  display: none;
}
