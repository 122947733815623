/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton-image {
  line-height: unset;
}
.ant-skeleton-image-wrapper > span {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-skeleton-avatar-wrapper > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-skeleton-avatar-wrapper > span .react-loading-skeleton {
  display: block;
  height: 100%;
}
.ant-skeleton-list-meta .ant-card-meta-detail {
  width: 100%;
}
