/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-order-item-photo .ant-image {
  width: 150px;
  height: auto;
}
.ant-table .ant-table-row:hover .ant-table-cell {
  background-color: var(--table-row-hover-bg);
}
.ant-statement-summary .ant-list-header {
  padding-bottom: 0;
  border-bottom: 0;
}
.ant-statement-summary.ant-list-split .ant-list-item:last-child {
  border-bottom: unset;
}
.ant-payment-totals .ant-typography {
  font-size: 18px;
}
.ant-payment-totals-item {
  padding: 12px;
  background-color: var(--table-header-bg);
}
.ant-payment-table .ant-image {
  width: 200px;
  height: 100px;
}
.ant-payment-table .ant-table-summary .ant-payment-summary-row:not(:last-child) .ant-payment-summary-row-item {
  padding-bottom: 0;
}
.ant-payment-table .ant-table-summary .ant-payment-summary-row-item {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}
.ant-payment-table .ant-table-summary > tr > th,
.ant-payment-table .ant-table-summary > tr > td {
  border-bottom: none;
}
