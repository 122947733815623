/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@media (max-width: 991px) {
  #ppv.ant-scrolling-effect {
    touch-action: none;
    overflow: hidden;
  }
}
#ppv .ant-drawer {
  max-width: 100%;
  box-shadow: none;
}
#ppv .ant-drawer ::-webkit-scrollbar {
  display: none;
}
#ppv .ant-drawer:not(#ppv .ant-drawer-open) {
  pointer-events: none;
}
#ppv .ant-drawer:not(#ppv .ant-drawer-open) .ant-drawer-close {
  display: none;
}
#ppv .ant-drawer-header-close-only {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  pointer-events: none;
}
#ppv .ant-drawer-header-close-only .ant-drawer-header-title {
  display: block;
}
@media (max-width: 767px) {
  #ppv .ant-drawer-header-close-only {
    background: #f0f0f0;
    background: linear-gradient(0, transparent 0%, rgba(255, 255, 255, 0.5) 100%);
  }
}
#ppv .ant-drawer-header-close-only + .ant-drawer-body {
  padding: 40px;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 767px) {
  #ppv .ant-drawer-header-close-only + .ant-drawer-body {
    padding: 40px 24px;
  }
}
@media (max-width: 768px) {
  #ppv .ant-drawer-header-close-only + .ant-drawer-body {
    padding-bottom: 200px;
  }
}
#ppv .ant-drawer-header {
  padding: 48px 40px 24px 40px;
  padding-bottom: 0 !important;
  border-bottom: none;
  background-color: transparent;
}
#ppv .ant-drawer-title {
  padding: var(--page-header-padding);
  font-weight: 800;
  font-size: 28px;
  font-family: var(--font-family-primary);
  line-height: 36px;
}
@media (max-width: 1199px) {
  #ppv .ant-drawer-title {
    text-transform: uppercase;
  }
}
#ppv .ant-drawer-content {
  overflow: visible;
  max-width: 100vw;
  background-color: var(--drawer-bg);
}
#ppv .ant-drawer-content-wrapper {
  max-width: 100%;
}
#ppv .ant-drawer-close {
  pointer-events: all;
}
#ppv .ant-drawer-close span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--drawer-header-close-size);
  height: var(--drawer-header-close-size);
  background-color: var(--drawer-header-close-bg);
  border-radius: 50%;
  transition: background-color 0.5s;
  border: 1px solid var(--border-color-base);
  color: var(--drawer-header-close-color);
}
#ppv .ant-drawer-close:hover span {
  background-color: var(--drawer-header-close-hover-bg);
  color: var(--drawer-header-close-hover-color);
}
#ppv .ant-drawer-top .ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
#ppv .ant-drawer-left .ant-drawer-header-close-only {
  padding: 0;
}
#ppv .ant-drawer-left .ant-drawer-header-close-only .ant-drawer-close {
  margin: 0;
  position: absolute;
  padding: 0;
  top: 25px;
}
@media (min-width: 992px) {
  #ppv .ant-drawer-left .ant-drawer-header-close-only .ant-drawer-close {
    top: 40px;
    right: auto;
    left: 0;
  }
}
#ppv .ant-drawer-right .ant-drawer-close {
  position: absolute;
  padding: 0;
  top: 25px;
  right: 25px;
}
@media (min-width: 992px) {
  #ppv .ant-drawer-right .ant-drawer-close {
    top: 40px;
    right: auto;
    left: 0;
  }
}
@media (min-width: 992px) {
  #ppv .ant-drawer-right.ant-drawer-open .ant-drawer-close {
    left: -25px;
  }
}
@media (min-width: 992px) {
  #ppv .ant-drawer-right-small {
    width: 350px !important;
  }
}
@media (min-width: 992px) {
  #ppv .ant-drawer-right-medium {
    max-width: 500px !important;
  }
}
@media (min-width: 992px) {
  #ppv .ant-drawer-right-large {
    width: 650px !important;
  }
}
#ppv .ant-drawer-right.ant-drawer-open {
  max-width: 100%;
}
@media (max-width: 991px) {
  #ppv .ant-drawer-right.ant-drawer-open {
    width: 100%;
  }
}
#ppv .ant-drawer-right.ant-drawer-open .ant-drawer-close {
  display: block;
}
