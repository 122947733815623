/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert-message {
  font-weight: var(--font-weight-strong);
}
.ant-alert-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.ant-alert-fixed-bottom .ant-alert {
  margin-bottom: 0;
  padding-bottom: 40px;
}
.ant-alert-with-description .ant-alert-message {
  font-size: 16px;
}
.ant-alert-with-description .ant-alert-icon {
  font-size: var(--alert-with-description-icon-size);
}
.ant-alert-info {
  color: var(--alert-info-message-color);
  background-color: var(--alert-info-bg-color);
  border-color: var(--alert-info-border-color);
}
.ant-alert-info .ant-alert-message,
.ant-alert-info .ant-alert-description,
.ant-alert-info .ant-alert-close-icon,
.ant-alert-info .ant-alert-icon {
  color: var(--alert-info-message-color);
}
.ant-alert-info .ant-alert-message .ant-typography,
.ant-alert-info .ant-alert-description .ant-typography,
.ant-alert-info .ant-alert-close-icon .ant-typography,
.ant-alert-info .ant-alert-icon .ant-typography,
.ant-alert-info .ant-alert-message svg,
.ant-alert-info .ant-alert-description svg,
.ant-alert-info .ant-alert-close-icon svg,
.ant-alert-info .ant-alert-icon svg {
  color: var(--alert-info-icon-color);
}
.ant-alert-error {
  color: var(--alert-error-message-color);
  border-color: var(--alert-error-border-color);
  background-color: var(--alert-error-bg-color);
}
.ant-alert-error .ant-alert-message,
.ant-alert-error .ant-alert-description,
.ant-alert-error .ant-alert-close-icon,
.ant-alert-error .ant-alert-icon {
  color: var(--alert-error-message-color);
}
.ant-alert-error .ant-alert-message .ant-typography,
.ant-alert-error .ant-alert-description .ant-typography,
.ant-alert-error .ant-alert-close-icon .ant-typography,
.ant-alert-error .ant-alert-icon .ant-typography,
.ant-alert-error .ant-alert-message svg,
.ant-alert-error .ant-alert-description svg,
.ant-alert-error .ant-alert-close-icon svg,
.ant-alert-error .ant-alert-icon svg {
  color: var(--alert-error-icon-color);
}
.ant-alert-error .ant-btn-ghost {
  color: var(--alert-error-message-color);
}
.ant-alert-error .ant-btn:disabled {
  color: rgba(var(--alert-error-message-color-rgb), 0.75);
}
.ant-alert-success {
  color: var(--alert-success-message-color);
  border-color: var(--alert-success-border-color);
  background-color: var(--alert-success-bg-color);
}
.ant-alert-success .ant-alert-message,
.ant-alert-success .ant-alert-description,
.ant-alert-success .ant-alert-close-icon,
.ant-alert-success .ant-alert-icon {
  color: var(--alert-success-message-color);
}
.ant-alert-success .ant-alert-message .ant-typography,
.ant-alert-success .ant-alert-description .ant-typography,
.ant-alert-success .ant-alert-close-icon .ant-typography,
.ant-alert-success .ant-alert-icon .ant-typography,
.ant-alert-success .ant-alert-message svg,
.ant-alert-success .ant-alert-description svg,
.ant-alert-success .ant-alert-close-icon svg,
.ant-alert-success .ant-alert-icon svg {
  color: var(--alert-success-icon-color);
}
.ant-alert.inverse {
  color: var(--text-color-inverse) !important;
  background-color: var(--text-color);
  border-color: var(--text-color);
}
.ant-alert.inverse .ant-alert-icon {
  color: var(--text-color-inverse) !important;
}
.ant-alert.inverse .ant-alert-content .ant-typography span,
.ant-alert.inverse .ant-alert-content .ant-typography {
  color: var(--text-color-inverse) !important;
}
