/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-paywall-content {
  display: flex;
  align-items: center;
  height: 100%;
  flex-flow: column-reverse;
}
@media (min-width: 768px) {
  .ant-paywall-content {
    flex-flow: unset;
  }
}
.ant-paywall .ant-overlay-spinner {
  width: 100%;
  height: unset;
}
@media (min-width: 768px) {
  .ant-btn.ant-paywall-logout {
    position: absolute;
    top: -60px;
    right: 0;
  }
}
