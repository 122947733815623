/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-section {
  overflow: visible;
}
.ant-section-title {
  font-family: var(--layout-section-title-font-family);
}
.ant-section + .ant-section {
  margin-top: 24px;
}
@media (max-width: 575px) {
}
@media (max-width: 1199px) {
  .ant-section .slick-list {
    overflow: visible;
  }
}
