/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-money input[type='number']::-webkit-inner-spin-button,
.ant-input-money input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.ant-input-number-custom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  user-select: none;
  width: auto !important;
}
.ant-input-number-custom,
.ant-input-number-custom:focus {
  position: relative;
  border: none !important;
  box-shadow: none !important;
}
.ant-input-number-custom .ant-input-number-input-wrap,
.ant-input-number-custom .ant-input-number-input {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
.ant-input-number-custom.ant-input-number-small {
  width: 108px;
  height: 36px;
  user-select: none;
}
.ant-input-number-custom.ant-input-number-small:hover,
.ant-input-number-custom.ant-input-number-small-focused {
  height: 36px;
}
.ant-input-number-custom.ant-input-number-small .ant-input-number-input {
  width: 36px;
  height: 36px;
  margin: 0 36px;
}
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-up,
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-down {
  background-color: transparent;
}
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-up:hover,
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-down:hover {
  height: 36px !important;
}
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler {
  width: 36px !important;
  height: 36px !important;
}
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-wrap .ant-input-number-handler-down,
.ant-input-number-custom.ant-input-number-small .ant-input-number-handler-wrap .ant-input-number-handler-up {
  right: calc(36px / 3);
}
.ant-input-number-custom.ant-input-number-middle {
  width: 144px;
  height: 48px;
  user-select: none;
}
.ant-input-number-custom.ant-input-number-middle:hover,
.ant-input-number-custom.ant-input-number-middle-focused {
  height: 48px;
}
.ant-input-number-custom.ant-input-number-middle .ant-input-number-input {
  width: 48px;
  height: 48px;
  margin: 0 48px;
}
.ant-input-number-custom.ant-input-number-middle .ant-input-number-handler-up:hover,
.ant-input-number-custom.ant-input-number-middle .ant-input-number-handler-down:hover {
  height: 48px !important;
}
.ant-input-number-custom.ant-input-number-middle .ant-input-number-handler {
  width: 48px !important;
  height: 48px !important;
}
.ant-input-number-custom.ant-input-number-middle .ant-input-number-handler-wrap .ant-input-number-handler-down,
.ant-input-number-custom.ant-input-number-middle .ant-input-number-handler-wrap .ant-input-number-handler-up {
  right: calc(48px / 3);
}
.ant-input-number-custom.ant-input-number-large {
  width: 168px;
  height: 56px;
  user-select: none;
}
.ant-input-number-custom.ant-input-number-large:hover,
.ant-input-number-custom.ant-input-number-large-focused {
  height: 56px;
}
.ant-input-number-custom.ant-input-number-large .ant-input-number-input {
  width: 56px;
  height: 56px;
  margin: 0 56px;
}
.ant-input-number-custom.ant-input-number-large .ant-input-number-handler-up:hover,
.ant-input-number-custom.ant-input-number-large .ant-input-number-handler-down:hover {
  height: 56px !important;
}
.ant-input-number-custom.ant-input-number-large .ant-input-number-handler {
  width: 56px !important;
  height: 56px !important;
}
.ant-input-number-custom .ant-input-number-input {
  padding: 0;
  text-align: center;
  border: 1px solid var(--input-border-color);
}
.ant-input-number-custom .ant-input-number-input-wrap {
  width: auto;
  opacity: 1;
  transition: width 0.3s;
}
.ant-input-number-custom .ant-input-number-handler-wrap {
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: transparent;
  border: none !important;
  opacity: 1;
  transition: width 0.3s;
}
.ant-input-number-custom .ant-input-number-handler {
  position: absolute;
  top: 50%;
  background-color: transparent;
  border: 0;
  transform: translateY(-50%);
  color: var(--primary-color);
}
.ant-input-number-custom .ant-input-number-handler:active,
.ant-input-number-custom .ant-input-number-handler:hover,
.ant-input-number-custom .ant-input-number-handler:focus {
  background: transparent;
}
.ant-input-number-custom:hover .ant-input-number-handler,
.ant-input-number-custom:focus .ant-input-number-handler {
  border: 0;
}
.ant-input-number-custom.ant-up-disabled .ant-input-number-handler-up {
  color: var(--border-color-base);
  cursor: not-allowed;
}
.ant-input-number-custom.ant-down-disabled .ant-input-number-handler-down {
  color: var(--border-color-base);
  cursor: not-allowed;
}
.ant-input-number-custom .ant-input-number-handler-up {
  right: 0;
}
.ant-input-number-custom .ant-input-number-handler-down {
  left: 0;
}
