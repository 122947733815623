/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse.ant-collapse-expandable {
  border: none !important;
  display: flex;
  background-color: transparent;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-item {
  display: flex;
  flex-direction: column;
  border-bottom: none !important;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-item > .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  padding: 0;
  padding-left: 0;
  background-color: transparent;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  transform: unset;
  margin-left: 12px;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-item > .ant-collapse-header .ant-collapse {
  right: -25px;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-content {
  border-top: none;
  background-color: transparent;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-content-box {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.ant-collapse.ant-collapse-expandable .ant-collapse-content-box .ant-list-item {
  border-bottom: none;
  padding: 5px 0 0;
}
