/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form-large .ant-form-item-control-input {
  min-height: 20px;
}
.ant-switch-checked {
  background-color: var(--primary-color);
}
