/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popconfirm .ant-popover-message {
  display: flex;
  align-items: flex-end;
}
.ant-popconfirm .ant-popover-message-title {
  padding-left: 10px;
}
.ant-popconfirm .ant-popover-message .anticon {
  position: relative;
  top: auto;
}
